export const botAvatar =
  'data:image/svg+xml;base64,PCFE' +
  'T0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1' +
  'czQy8vRFREIFNWRyAxLjEvL0VOIiAi' +
  'aHR0cDovL3d3dy53My5vcmcvR3JhcG' +
  'hpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEu' +
  'ZHRkIj4KDTwhLS0gVXBsb2FkZWQgdG' +
  '86IFNWRyBSZXBvLCB3d3cuc3ZncmVw' +
  'by5jb20sIFRyYW5zZm9ybWVkIGJ5Oi' +
  'BTVkcgUmVwbyBNaXhlciBUb29scyAt' +
  'LT4KPHN2ZyBoZWlnaHQ9IjI1NnB4Ii' +
  'B3aWR0aD0iMjU2cHgiIHZlcnNpb249' +
  'IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG' +
  '5zPSJodHRwOi8vd3d3LnczLm9yZy8y' +
  'MDAwL3N2ZyIgeG1sbnM6eGxpbms9Im' +
  'h0dHA6Ly93d3cudzMub3JnLzE5OTkv' +
  'eGxpbmsiIHZpZXdCb3g9Ii0zMi42OS' +
  'AtMzIuNjkgMzkyLjMwIDM5Mi4zMCIg' +
  'eG1sOnNwYWNlPSJwcmVzZXJ2ZSIgZm' +
  'lsbD0iIzAwMDAwMCIgc3Ryb2tlPSIj' +
  'MDAwMDAwIiBzdHJva2Utd2lkdGg9Ij' +
  'AuMDAzMjY5MjAwMDAwMDAwMDAwOCIg' +
  'dHJhbnNmb3JtPSJyb3RhdGUoMCkiPg' +
  'oNPGcgaWQ9IlNWR1JlcG9fYmdDYXJy' +
  'aWVyIiBzdHJva2Utd2lkdGg9IjAiLz' +
  '4KDTxnIGlkPSJTVkdSZXBvX3RyYWNl' +
  'ckNhcnJpZXIiIHN0cm9rZS1saW5lY2' +
  'FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVq' +
  'b2luPSJyb3VuZCIgc3Ryb2tlPSIjQ0' +
  'NDQ0NDIiBzdHJva2Utd2lkdGg9IjYu' +
  'NTM4NCIvPgoNPGcgaWQ9IlNWR1JlcG' +
  '9faWNvbkNhcnJpZXIiPiA8ZyBpZD0i' +
  'WE1MSURfMzRfIj4gPGcgaWQ9IlhNTE' +
  'lEXzQ1XyI+IDxwYXRoIGlkPSJYTUxJ' +
  'RF80N18iIHN0eWxlPSJmaWxsOiMyMm' +
  'EwOWU7IiBkPSJNMjQyLjgzNywyMzQu' +
  'MTUzYy02LjA0My0zLjY0MS0xMi42OS' +
  '02LjQzLTE5Ljg0NC04LjE5NiBjLTQu' +
  'ODI0LTEuMjA2LTE3LjA0OC0xLjk0OS' +
  '0yNS4wMTgtMi4xODhjLTguMTg5LDgu' +
  'NTQzLTIwLjYwOCwxMy45OTQtMzQuNT' +
  'E1LDEzLjk5NHMtMjQuMzI2LTUuNDUx' +
  'LTMyLjUxNS0xMy45OTQgYy03Ljk2OS' +
  'wwLjIzOS0yMi4xOTQsMC45ODItMjcu' +
  'MDE4LDIuMTg4Yy03LjE1NCwxLjc2Ni' +
  '0xMy44MDIsNC41NTQtMTkuODQ0LDgu' +
  'MTk2Yy0yMy4yMjgsMTMuOTUxLTM3Lj' +
  'YwNSw0MC40ODItMzcuNjA1LDcwLjkx' +
  'MyB2MjEuODQ5aDIzMy45NjN2LTIxLj' +
  'g0OUMyODAuNDQyLDI3NC42MzUsMjY2' +
  'LjA2NCwyNDguMTA0LDI0Mi44MzcsMj' +
  'M0LjE1M3oiLz4gPHBhdGggaWQ9IlhN' +
  'TElEXzQ2XyIgc3R5bGU9ImZpbGw6Iz' +
  'IyYTA5ZTsiIGQ9Ik0yODAuNDQsMzA1' +
  'LjA3djIxLjg1SDE2My40NnYtODkuMT' +
  'ZjMTMuOTEsMCwyNC4zMy01LjQ1LDMy' +
  'LjUxLTEzLjk5IGM3Ljk3LDAuMjQsMj' +
  'IuMiwwLjk4LDI3LjAyLDIuMTljNy4x' +
  'NiwxLjc2LDEzLjgsNC41NSwxOS44NS' +
  'w4LjE5YzE3LjIzLDEwLjM1LDI5LjU5' +
  'LDI3LjYzLDM0LjgyLDQ4LjI4IEMyNz' +
  'kuNDgsMjg5LjYyLDI4MC40NCwyOTcu' +
  'MjEsMjgwLjQ0LDMwNS4wN3oiLz4gPC' +
  '9nPiA8ZyBpZD0iWE1MSURfNDBfIj4g' +
  'PHBhdGggaWQ9IlhNTElEXzE2OV8iIH' +
  'N0eWxlPSJmaWxsOiM2ZjQ4MzQ7IiBk' +
  'PSJNMjY4Ljg0LDEyNy4yNmMtMi4wOC' +
  '02MC45MS0yOS43OC0xMDcuOS03NC41' +
  'Ny0xMjIuNTFjLTAuMDEsMC0wLjAxLT' +
  'AuMDEtMC4wMi0wLjAxIEMxODQuNzMs' +
  'MS42NCwxNzQuNDQsMCwxNjMuNDYsMG' +
  'MtMTIuMzksMC0yMy45MSwyLjA5LTM0' +
  'LjQyLDYuMDFDODYuMzgsMjEuOTIsNj' +
  'AuMTEsNjgsNTguMDgsMTI3LjI2IGMt' +
  'OS4zNiw3LjU5LTE1LjM1LDE5LjE5LT' +
  'E1LjM1LDMyLjE5YzAsMjIuOSwxOC41' +
  'Niw0MS40Niw0MS40Niw0MS40NmMzMC' +
  '44NiwwLDU1LjU5LDE3LjA4LDc4LjU0' +
  'LDE3LjAxYzAuMjQsMCwwLjQ5LTAuMD' +
  'EsMC43My0wLjAyIGMwLjM2LTAuMDEs' +
  'MC43MS0wLjAyLDEuMDctMC4wM2MwLj' +
  'UzLTAuMDIsMS4wNy0wLjA1LDEuNjEt' +
  'MC4wOWMxLjA3LTAuMDcsMi4xNi0wLj' +
  'E4LDMuMjQtMC4zMmMwLjU1LTAuMDcs' +
  'MS4wOS0wLjE1LDEuNjQtMC4yMyBjMC' +
  '43Mi0wLjEyLDEuNDUtMC4yNCwyLjE5' +
  'LTAuMzdjMC43My0wLjE0LDEuNDctMC' +
  '4yOSwyLjIxLTAuNDRjMC4zNy0wLjA4' +
  'LDAuNzQtMC4xNiwxLjExLTAuMjRjMC' +
  '4zNy0wLjA5LDAuNzQtMC4xNywxLjEy' +
  'LTAuMjYgYzAuMzctMC4wOSwwLjc0LT' +
  'AuMTgsMS4xMi0wLjI3YzAuNzUtMC4x' +
  'OSwxLjUtMC4zOCwyLjI1LTAuNThjMC' +
  '4xOS0wLjA1LDAuMzgtMC4xLDAuNTct' +
  'MC4xNWMxLjUzLTAuNDEsMy4wNy0wLj' +
  'g2LDQuNjMtMS4zMyBjMC4xOC0wLjA2' +
  'LDAuMzUtMC4xMSwwLjUzLTAuMTZjMS' +
  '45My0wLjU5LDMuODgtMS4yMSw1Ljg2' +
  'LTEuODRjMC4yNC0wLjA4LDAuNDgtMC' +
  '4xNiwwLjcyLTAuMjNjMC40LTAuMTQs' +
  'MC44LTAuMjcsMS4yMS0wLjM5IGMwLj' +
  'E1LTAuMDYsMC4zLTAuMTEsMC40NS0w' +
  'LjE1YzAuNC0wLjEzLDAuOC0wLjI2LD' +
  'EuMi0wLjM5czAuODEtMC4yNywxLjIx' +
  'LTAuMzljMS4wNy0wLjM1LDIuMTYtMC' +
  '43LDMuMjYtMS4wNSBjMC40MS0wLjE0' +
  'LDAuODItMC4yNywxLjI0LTAuNGMwLj' +
  'k1LTAuMywxLjkxLTAuNjEsMi44Ny0w' +
  'LjljMC43Mi0wLjIzLDEuNDQtMC40NS' +
  'wyLjE3LTAuNjZjMS43LTAuNTIsMy40' +
  'MS0xLjAxLDUuMTUtMS40OSBjMC40NC' +
  '0wLjEyLDAuODctMC4yNCwxLjMxLTAu' +
  'MzVjMC45Ni0wLjI2LDEuOTMtMC41MS' +
  'wyLjktMC43NmMwLjgtMC4xOSwxLjYt' +
  'MC4zOSwyLjQtMC41N2MwLjQ1LTAuMS' +
  'wwLjktMC4yMSwxLjM1LTAuMyBjMC4w' +
  'Ny0wLjAyLDAuMTUtMC4wNCwwLjIzLT' +
  'AuMDVjMC40NC0wLjEsMC44OS0wLjIs' +
  'MS4zNC0wLjI5YzAuNDgtMC4xLDAuOT' +
  'YtMC4yLDEuNDQtMC4yOWMwLjQyLTAu' +
  'MDksMC44NC0wLjE3LDEuMjYtMC4yNC' +
  'BjMi4yMy0wLjQyLDQuNDktMC43Nyw2' +
  'Ljc5LTEuMDVjMC4zOC0wLjA1LDAuNz' +
  'ctMC4xLDEuMTYtMC4xNGMwLjctMC4w' +
  'OCwxLjQtMC4xNSwyLjEtMC4yMWMwLj' +
  'M3LTAuMDMsMC43My0wLjA2LDEuMS0w' +
  'LjA5IGMyLjM4LTAuMTksNC44LTAuMj' +
  'ksNy4yNi0wLjI5YzIxLjgsMCw0MS40' +
  'Ni0xOC41Niw0MS40Ni00MS40NkMyOD' +
  'QuMTksMTQ2LjQ1LDI3OC4yLDEzNC44' +
  'NSwyNjguODQsMTI3LjI2eiIvPiA8cG' +
  'F0aCBpZD0iWE1MSURfMTk3XyIgc3R5' +
  'bGU9ImZpbGw6IzZmNDgzNDsiIGQ9Ik' +
  '0yNjguODQsMTI3LjI2Yy0yLjA4LTYw' +
  'LjkxLTI5Ljc4LTEwNy45LTc0LjU3LT' +
  'EyMi41MWMtMC4wMSwwLTAuMDEtMC4w' +
  'MS0wLjAyLTAuMDEgQzE4NC43MywxLj' +
  'Y0LDE3NC40NCwwLDE2My40NiwwdjIw' +
  'Ni44NnYxMS4wNGMwLjM2LTAuMDEsMC' +
  '43MS0wLjAyLDEuMDctMC4wM2MwLjUz' +
  'LTAuMDIsMS4wNy0wLjA1LDEuNjEtMC' +
  '4wOSBjMS4wNy0wLjA4LDIuMTYtMC4x' +
  'OSwzLjI0LTAuMzJjMC41NS0wLjA3LD' +
  'EuMDktMC4xNSwxLjY0LTAuMjNjMC43' +
  'Mi0wLjEyLDEuNDUtMC4yNCwyLjE5LT' +
  'AuMzdjMC43My0wLjE0LDEuNDctMC4y' +
  'OSwyLjIxLTAuNDQgYzAuMzctMC4wOC' +
  'wwLjc0LTAuMTYsMS4xMS0wLjI0YzAu' +
  'MzctMC4wOSwwLjc0LTAuMTcsMS4xMi' +
  '0wLjI2YzAuMzctMC4wOSwwLjc0LTAu' +
  'MTgsMS4xMi0wLjI3YzAuNzUtMC4xOS' +
  'wxLjUtMC4zOCwyLjI1LTAuNTggYzAu' +
  'MTktMC4wNSwwLjM4LTAuMSwwLjU3LT' +
  'AuMTVjMS41My0wLjQyLDMuMDctMC44' +
  'Nyw0LjYzLTEuMzNjMC4xOC0wLjA2LD' +
  'AuMzUtMC4xMSwwLjUzLTAuMTZjMS45' +
  'My0wLjU5LDMuODgtMS4yMSw1Ljg2LT' +
  'EuODQgYzAuMjQtMC4wOCwwLjQ4LTAu' +
  'MTYsMC43Mi0wLjIzYzAuNC0wLjE0LD' +
  'AuOC0wLjI3LDEuMjEtMC4zOWMwLjE1' +
  'LTAuMDYsMC4zLTAuMTEsMC40NS0wLj' +
  'E1YzAuNC0wLjEzLDAuOC0wLjI2LDEu' +
  'Mi0wLjM5IHMwLjgxLTAuMjcsMS4yMS' +
  '0wLjM5YzEuMDgtMC4zNiwyLjE3LTAu' +
  'NzEsMy4yNi0xLjA1YzAuNDEtMC4xNC' +
  'wwLjgyLTAuMjcsMS4yNC0wLjRjMC45' +
  'NS0wLjMsMS45MS0wLjYxLDIuODctMC' +
  '45IGMwLjcyLTAuMjMsMS40NC0wLjQ1' +
  'LDIuMTctMC42NmMxLjctMC41MiwzLj' +
  'QxLTEuMDEsNS4xNS0xLjQ5YzAuNDQt' +
  'MC4xMiwwLjg3LTAuMjQsMS4zMS0wLj' +
  'M1YzAuOTYtMC4yNiwxLjkzLTAuNTEs' +
  'Mi45LTAuNzYgYzAuOC0wLjE5LDEuNi' +
  '0wLjM5LDIuNC0wLjU3YzAuNDUtMC4x' +
  'LDAuOS0wLjIxLDEuMzUtMC4zYzAuMD' +
  'ctMC4wMiwwLjE1LTAuMDQsMC4yMy0w' +
  'LjA1YzAuNDQtMC4xLDAuODktMC4yLD' +
  'EuMzQtMC4yOSBjMC40OC0wLjEsMC45' +
  'Ni0wLjIsMS40NC0wLjI5YzAuNDItMC' +
  '4wOSwwLjg0LTAuMTcsMS4yNi0wLjI0' +
  'YzIuMjMtMC40Miw0LjQ5LTAuNzcsNi' +
  '43OS0xLjA1YzAuMzgtMC4wNSwwLjc3' +
  'LTAuMSwxLjE2LTAuMTQgYzAuNy0wLj' +
  'A4LDEuNC0wLjE1LDIuMS0wLjIxYzAu' +
  'MzctMC4wMywwLjczLTAuMDYsMS4xLT' +
  'AuMDljMi4zOC0wLjE5LDQuOC0wLjI5' +
  'LDcuMjYtMC4yOWMyMS44LDAsNDEuND' +
  'YtMTguNTYsNDEuNDYtNDEuNDYgQzI4' +
  'NC4xOSwxNDYuNDUsMjc4LjIsMTM0Lj' +
  'g1LDI2OC44NCwxMjcuMjZ6Ii8+IDwv' +
  'Zz4gPGcgaWQ9IlhNTElEXzM5XyI+ID' +
  'xwYXRoIGlkPSJYTUxJRF8yMjFfIiBz' +
  'dHlsZT0iZmlsbDojZTJjNWEyOyIgZD' +
  '0iTTE4OC43NDksMTY4LjkxOUgxNjQu' +
  'NzVoLTEuMTIxaC0wLjMzOGgtMS4xMj' +
  'FoLTIzLjk5OCBjMCw0Mi44MDMtMTku' +
  'NjQ2LDU4LjgwNC0yOC43NzYsNjQuNj' +
  'czbDguOTI3LDQxLjA3NWMxNC4zNTYs' +
  'MTguNDEyLDM2LjY4OCwyNS45NTIsND' +
  'MuODQ4LDI4Ljg2NmgyLjU4MSBjNy4x' +
  'Ni0yLjkxNCwyOS40OTItMTAuNDU0LD' +
  'QzLjg0OC0yOC44NjZsOC45MjctNDEu' +
  'MDc1QzIwOC4zOTUsMjI3LjcyMywxOD' +
  'guNzQ5LDIxMS43MjIsMTg4Ljc0OSwx' +
  'NjguOTE5eiIvPiA8cGF0aCBpZD0iWE' +
  '1MSURfMjIyXyIgc3R5bGU9ImZpbGw6' +
  'I2UyYzVhMjsiIGQ9Ik0yMTcuNTI1LD' +
  'IzMy41OTJjLTkuMTMtNS44NjktMjgu' +
  'Nzc2LTIxLjg3LTI4Ljc3Ni02NC42Nz' +
  'NIMTY0Ljc1aC0xLjEyMWgtMC4xNjkg' +
  'djEzNC42MTRoMS4yOWM3LjE2LTIuOT' +
  'E0LDI5LjQ5Mi0xMC40NTQsNDMuODQ4' +
  'LTI4Ljg2NkwyMTcuNTI1LDIzMy41OT' +
  'J6Ii8+IDwvZz4gPGcgaWQ9IlhNTElE' +
  'XzM4XyI+IDxwYXRoIGlkPSJYTUxJRF' +
  '8yMjNfIiBzdHlsZT0iZmlsbDojZTJj' +
  'NWEyOyIgZD0iTTE0My45MzgsNzQuNz' +
  'U1Yy0xLjYwMS0yLjc0Ny0zLjA3NC01' +
  'LjM3Ni00LjQ0NC03LjkxNiBjLTguMT' +
  'Q3LDE4LjQ5LTI3LjUyNSw0MS43Mi00' +
  'MC4wMDUsNDQuODMzYzEuMDA1LDIxLj' +
  'EyNCw0LjM5OCwzOS45MTYsOC4zMTYs' +
  'NDcuMTQ5YzkuMDQyLDE2LjY5NCwyOS' +
  '4yMjEsMzguOTU3LDU1LjY1NywzOC45' +
  'NTcgYzI2LjQzMSwwLDQ2LjYwNy0yMi' +
  '4yNjIsNTUuNjUyLTM4Ljk1N2MzLjky' +
  'NC03LjI0NCw3LjMyMi0yNi4wODIsOC' +
  '4zMjItNDcuMjQ2QzIxMy41NDIsMTEw' +
  'LjQ5OSwxNjEuMjc3LDEwNC41MTQsMT' +
  'QzLjkzOCw3NC43NTV6Ii8+IDxwYXRo' +
  'IGlkPSJYTUxJRF8yMjRfIiBzdHlsZT' +
  '0iZmlsbDojZTJjNWEyOyIgZD0iTTE2' +
  'My40Niw5My41OTN2MTA0LjE4NmMwLD' +
  'AsMC4wMDEsMCwwLjAwMiwwYzI2LjQz' +
  'MSwwLDQ2LjYwNy0yMi4yNjIsNTUuNj' +
  'UyLTM4Ljk1NyBjMy45MjQtNy4yNDUs' +
  'Ny4zMjItMjYuMDgzLDguMzIyLTQ3Lj' +
  'I0NkMyMTcuMTgxLDExMC43OCwxODYu' +
  'MDQ3LDEwNy4zMDksMTYzLjQ2LDkzLj' +
  'U5M3oiLz4gPC9nPiA8ZyBpZD0iWE1M' +
  'SURfMzVfIj4gPHBhdGggaWQ9IlhNTE' +
  'lEXzM3XyIgc3R5bGU9ImZpbGw6I2Uz' +
  'ZTNlMzsiIGQ9Ik0xMTIuOTE2LDI0OS' +
  '43OTNsNS40MDYsMjUuNjgyYzE0LjM1' +
  'NiwxOC40MTIsMzYuNjg4LDI1Ljk1Mi' +
  'w0My44NDgsMjguODY2aDIuNTgxIGM3' +
  'LjE2LTIuOTE0LDI5LjQ5Mi0xMC40NT' +
  'QsNDMuODQ4LTI4Ljg2Nmw1LjQyNC0y' +
  'NS42ODNMMTEyLjkxNiwyNDkuNzkzTD' +
  'ExMi45MTYsMjQ5Ljc5M3oiLz4gPHBh' +
  'dGggaWQ9IlhNTElEXzM2XyIgc3R5bG' +
  'U9ImZpbGw6I2UzZTNlMzsiIGQ9Ik0x' +
  'NjMuNDYsMjQ5Ljc5M3Y1NC41NDloMS' +
  '4yOWM3LjE2LTIuOTE0LDI5LjQ5Mi0x' +
  'MC40NTQsNDMuODQ4LTI4Ljg2Nmw1Lj' +
  'QyNC0yNS42ODMgTDE2My40NiwyNDku' +
  'NzkzTDE2My40NiwyNDkuNzkzeiIvPi' +
  'A8L2c+IDwvZz4gPC9nPgoNPC9zdmc+';

export const defaultAvatar =
  'data:image/svg+xml;base64,PD94' +
  'bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE' +
  '9DVFlQRSBzdmcgIFBVQkxJQyAnLS8v' +
  'VzNDLy9EVEQgU1ZHIDEuMS8vRU4nIC' +
  'AnaHR0cDovL3d3dy53My5vcmcvR3Jh' +
  'cGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMT' +
  'EuZHRkJz48c3ZnIGVuYWJsZS1iYWNr' +
  'Z3JvdW5kPSJuZXcgLTIwOC41IDIxID' +
  'EwMCAxMDAiIGlkPSJMYXllcl8xIiB2' +
  'ZXJzaW9uPSIxLjEiIHZpZXdCb3g9Ii' +
  '0yMDguNSAyMSAxMDAgMTAwIiB4bWw6' +
  'c3BhY2U9InByZXNlcnZlIiB4bWxucz' +
  '0iaHR0cDovL3d3dy53My5vcmcvMjAw' +
  'MC9zdmciIHhtbG5zOnNrZXRjaD0iaH' +
  'R0cDovL3d3dy5ib2hlbWlhbmNvZGlu' +
  'Zy5jb20vc2tldGNoL25zIiB4bWxucz' +
  'p4bGluaz0iaHR0cDovL3d3dy53My5v' +
  'cmcvMTk5OS94bGluayI+PGc+PGNpcm' +
  'NsZSBjeD0iLTE1OC41IiBjeT0iNzEi' +
  'IGZpbGw9IiNGNUVFRTUiIGlkPSJNYX' +
  'NrIiByPSI1MCIvPjxnPjxkZWZzPjxj' +
  'aXJjbGUgY3g9Ii0xNTguNSIgY3k9Ij' +
  'cxIiBpZD0iTWFza18yXyIgcj0iNTAi' +
  'Lz48L2RlZnM+PGNsaXBQYXRoIGlkPS' +
  'JNYXNrXzRfIj48dXNlIG92ZXJmbG93' +
  'PSJ2aXNpYmxlIiB4bGluazpocmVmPS' +
  'IjTWFza18yXyIvPjwvY2xpcFBhdGg+' +
  'PHBhdGggY2xpcC1wYXRoPSJ1cmwoI0' +
  '1hc2tfNF8pIiBkPSJNLTEwOC41LDEy' +
  'MXYtMTRjMCwwLTIxLjItNC45LTI4LT' +
  'YuN2MtMi41LTAuNy03LTMuMy03LTEy' +
  'ICAgICBjMC0xLjcsMC02LjMsMC02Lj' +
  'NoLTE1aC0xNWMwLDAsMCw0LjYsMCw2' +
  'LjNjMCw4LjctNC41LDExLjMtNywxMm' +
  'MtNi44LDEuOS0yOC4xLDcuMy0yOC4x' +
  'LDYuN3YxNGg1MC4xSC0xMDguNXoiIG' +
  'ZpbGw9IiNFNkMxOUMiIGlkPSJNYXNr' +
  'XzNfIi8+PGcgY2xpcC1wYXRoPSJ1cm' +
  'woI01hc2tfNF8pIj48ZGVmcz48cGF0' +
  'aCBkPSJNLTEwOC41LDEyMXYtMTRjMC' +
  'wwLTIxLjItNC45LTI4LTYuN2MtMi41' +
  'LTAuNy03LTMuMy03LTEyYzAtMS43LD' +
  'AtNi4zLDAtNi4zaC0xNWgtMTVjMCww' +
  'LDAsNC42LDAsNi4zICAgICAgIGMwLD' +
  'guNy00LjUsMTEuMy03LDEyYy02Ljgs' +
  'MS45LTI4LjEsNy4zLTI4LjEsNi43dj' +
  'E0aDUwLjFILTEwOC41eiIgaWQ9Ik1h' +
  'c2tfMV8iLz48L2RlZnM+PGNsaXBQYX' +
  'RoIGlkPSJNYXNrXzVfIj48dXNlIG92' +
  'ZXJmbG93PSJ2aXNpYmxlIiB4bGluaz' +
  'pocmVmPSIjTWFza18xXyIvPjwvY2xp' +
  'cFBhdGg+PHBhdGggY2xpcC1wYXRoPS' +
  'J1cmwoI01hc2tfNV8pIiBkPSJNLTE1' +
  'OC41LDEwMC4xYzEyLjcsMCwyMy0xOC' +
  '42LDIzLTM0LjQgICAgICBjMC0xNi4y' +
  'LTEwLjMtMjQuNy0yMy0yNC43cy0yMy' +
  'w4LjUtMjMsMjQuN0MtMTgxLjUsODEu' +
  'NS0xNzEuMiwxMDAuMS0xNTguNSwxMD' +
  'AuMXoiIGZpbGw9IiNENEIwOEMiIGlk' +
  'PSJoZWFkLXNoYWRvdyIvPjwvZz48L2' +
  'c+PHBhdGggZD0iTS0xNTguNSw5NmMx' +
  'Mi43LDAsMjMtMTYuMywyMy0zMWMwLT' +
  'E1LjEtMTAuMy0yMy0yMy0yM3MtMjMs' +
  'Ny45LTIzLDIzICAgIEMtMTgxLjUsNz' +
  'kuNy0xNzEuMiw5Ni0xNTguNSw5Nnoi' +
  'IGZpbGw9IiNGMkNFQTUiIGlkPSJoZW' +
  'FkIi8+PC9nPjwvc3ZnPg==';
