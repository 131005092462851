import LazyLoader from '@loadable/component';
import {
  URL_ASSESSMENTS,
  URL_CREATE_ASSESSMENTS,
  URL_EDIT_ASSESSMENTS,
  URL_CANDIDATES,
  URL_EMPLOYEES,
  URL_TALENT_INSIGHTS,
  URL_CREATE_CANDIDATE,
  URL_EDIT_CANDIDATE,
  URL_JOBS,
  URL_CREATE_JOB,
  URL_EDIT_JOB,
  URL_DASHBOARD,
  URL_ONA,
  URL_REPORTS_DETAILS,
  URL_SETTINGS,
  URL_ASSESSMENT_DETAILS,
} from 'Helpers/Paths';

const Main = [
  {
    path: '/',
    exact: true,
    redirect: URL_DASHBOARD,
  },
  {
    path: URL_DASHBOARD,
    exact: true,
    component: LazyLoader(() => import('Components/Dashboard/Dashboard')),
  },
  {
    path: URL_CANDIDATES,
    exact: true,
    component: LazyLoader(() => import('Components/Candidates/Candidates')),
    props: {
      mode: 'candidate',
    },
  },
  {
    path: URL_EMPLOYEES,
    exact: true,
    component: LazyLoader(() => import('Components/Candidates/Candidates')),
    props: {
      mode: 'employee',
    },
  },
  {
    path: URL_TALENT_INSIGHTS,
    exact: true,
    component: LazyLoader(() => import('Components/TeamFit/Dashboard')),
  },
  {
    path: URL_CREATE_CANDIDATE,
    exact: true,
    component: LazyLoader(() =>
      import('Components/Candidates/CreateCandidate'),
    ),
  },
  {
    path: URL_EDIT_CANDIDATE,
    exact: true,
    component: LazyLoader(() =>
      import('Components/Candidates/CreateCandidate'),
    ),
  },
  {
    path: URL_JOBS,
    exact: true,
    component: LazyLoader(() => import('Components/Jobs/Jobs')),
  },
  {
    path: URL_ASSESSMENTS,
    exact: true,
    component: LazyLoader(() => import('Components/Assessments/Assessments')),
  },
  {
    path: URL_ASSESSMENT_DETAILS,
    exact: true,
    component: LazyLoader(() =>
      import('Components/Assessments/AssessmentDetails'),
    ),
  },
  {
    path: URL_REPORTS_DETAILS,
    exact: true,
    component: LazyLoader(() => import('Components/Report/Report')),
  },
  {
    path: URL_ONA,
    exact: true,
    component: LazyLoader(() => import('Components/ONA/ONA')),
  },
  {
    path: URL_SETTINGS,
    exact: true,
    component: LazyLoader(() => import('Components/Settings/Settings')),
  },
  {
    path: URL_CREATE_JOB,
    exact: true,
    component: LazyLoader(() => import('Components/Commons/createNewJob')),
  },
  {
    path: URL_EDIT_JOB,
    exact: true,
    component: LazyLoader(() => import('Components/Commons/createNewJob')),
  },
  {
    path: URL_CREATE_ASSESSMENTS,
    exact: true,
    component: LazyLoader(() =>
      import('Components/Assessments/CreateAssessments'),
    ),
  },
  {
    path: URL_EDIT_ASSESSMENTS,
    exact: true,
    component: LazyLoader(() =>
      import('Components/Assessments/CreateAssessments'),
    ),
  },
];

export default Main;
