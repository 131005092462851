// CORE
// THEME
import { Layout } from 'antd';
import { useChatbotTheme, useIsLoggedIn } from 'hooks/auth';
import React from 'react';
import { connect } from 'react-redux';
// USER DEFINED
import Routes from 'Routes/Route';
import { ThemeProvider } from 'styled-components';
import { UtilityStyles } from '../Styles/Utils';
import FooterHTML from './Commons/Footer/Footer';
import CommonPagesHeader from './Commons/Header/CommonPagesHeader';
import Header from './Commons/Header/Header';
import Sidebar from './Commons/Sidebar/Sidebar';
//STYLES
import { RightContentWrapper } from './Website.style';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { WbhrChatBot } from './Chatbot';

const { Content, Footer } = Layout;

function Website(props) {
  const location = useLocation();
  const { i18n } = useTranslation();
  const isLoggedIn = useIsLoggedIn();
  const company = JSON.parse(localStorage.getItem('company'));
  const chatbotTheme = useChatbotTheme();

  return (
    <ThemeProvider theme={chatbotTheme}>
      {location.pathname === '/ona' &&
        isLoggedIn &&
        company.use_chatbot &&
        i18n.language === 'en' && <WbhrChatBot />}
      <React.Fragment>
        <Layout style={{ overflow: 'hidden' }}>
          {window.location.pathname === '/terms' ||
          window.location.pathname === '/privacy' ||
          isLoggedIn ? (
            <LoggedInComponents />
          ) : (
            <Routes />
          )}
        </Layout>
        <UtilityStyles />
      </React.Fragment>
    </ThemeProvider>
  );
}

function LoggedInComponents(props) {
  return (
    <>
      <RightContentWrapper>
        {window.location.pathname === '/terms' ||
        window.location.pathname === '/privacy' ? (
          <CommonPagesHeader />
        ) : (
          <Header />
        )}
        <Layout style={{ flexDirection: 'row', overflowX: 'hidden' }}>
          {window.location.pathname === '/terms' ||
          window.location.pathname === '/privacy' ? (
            <CommonPagesHeader />
          ) : (
            <Sidebar />
          )}
          <Layout className="contentMainLayout">
            <Content className="antd-content">
              <Routes />
            </Content>

            <Footer>
              <FooterHTML />
            </Footer>
          </Layout>
        </Layout>
      </RightContentWrapper>
    </>
  );
}

const mapReduxStateToProps = (state) => ({
  company: state.Company.company,
});

export default connect(mapReduxStateToProps)(Website);
