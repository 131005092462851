import {
  DownOutlined,
  LogoutOutlined,
  MenuOutlined,
  UserOutlined,
  GlobalOutlined,
} from '@ant-design/icons';
import { Button, Dropdown } from 'antd';
import LogoImage from 'Assets/Images/logo.png';
import userImage from 'Assets/Images/avatar.png';
import { useAccessTokenItem } from 'hooks/auth';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toggleCollapsed } from 'Redux/Auth/Actions';
import { setCompany, setUserData } from 'Redux/Company/Actions';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
// STYLES
import { HeaderWrapper, LogoWrapper } from './Header.style';

function Topbar(props) {
  const { t } = useTranslation();
  const accessTokenItem = useAccessTokenItem();

  const logout = React.useCallback(() => {
    var company = JSON.parse(localStorage.getItem('company'));

    accessTokenItem.removeItem();

    props.history.replace('/login?ref_id=' + company.ref_id);
  }, [props.history, accessTokenItem]);

  const menu = {
    items: [
      {
        key: '1',
        label: (
          <div
            onClick={() => {
              props.history.push('/settings');
            }}
          >
            {t('user_profile.Edit Profile')}
          </div>
        ),
        icon: <UserOutlined />,
      },
      {
        key: '2',
        label: (
          <div onClick={() => logout(props)}>{t('user_profile.Logout')}</div>
        ),
        icon: <LogoutOutlined />,
      },
    ],
  };

  const menu2 = {
    items: [
      {
        key: '1',
        label: (
          <div
            onClick={() => {
              document.body.dir = 'rtl';
              i18n.changeLanguage('ar');
            }}
          >
            اللغة العربية
          </div>
        ),
      },
      {
        key: '2',
        label: (
          <div
            onClick={() => {
              document.body.dir = 'ltr';
              i18n.changeLanguage('en');
            }}
          >
            English
          </div>
        ),
      },
    ],
  };

  return (
    <HeaderWrapper className="header" collapsed={props.collapsed.toString()}>
      <div className="header">
        <div
          className="isoLeft"
          style={{
            fontSize: '16px',
            color: '#040707',
            display: 'flex',
            alignItems: 'center',
            WebkitBoxAlign: 'center',
          }}
        >
          <MenuOutlined onClick={props.toggleCollapsed} />
          <LogoWrapper
            className="flex f-v-center f-h-center"
            style={{ margin: '0 20px 0 20px' }}
            companyId={props.company && props.company.id}
          >
            <img
              className="img-logo"
              src={
                props.company && props.company.logo
                  ? props.company.logo
                  : LogoImage
              }
              alt="Logo"
            />
          </LogoWrapper>
        </div>
        <div
          className="header-main"
          style={{
            display: 'flex',
            alignItems: 'center',
            WebkitBoxAlign: 'center',
          }}
        >
          <Dropdown menu={menu2}>
            <Button className="dropdown-profile">
              <GlobalOutlined
                style={{
                  // width: '30px',
                  marginRight: '15px',
                  marginLeft: '15px',
                  borderRadius: '50%',
                }}
              />
              {t('shared.current-language')}
              <DownOutlined />
            </Button>
          </Dropdown>
          <Dropdown menu={menu}>
            <Button
              className="dropdown-profile"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                ...(i18n.dir() === 'ltr'
                  ? { marginRight: 42 }
                  : { marginLeft: 42 }),
              }}
            >
              {/* <UserOutlined /> */}
              {props.userData && props.userData.profile ? (
                <img
                  alt="User profile"
                  style={{
                    width: '30px',
                    marginRight: '15px',
                    marginLeft: '15px',
                    borderRadius: '50%',
                  }}
                  src={
                    props.userData.profile ? props.userData.profile : userImage
                  }
                />
              ) : !props.userData.profile ? (
                <img
                  alt="User profile"
                  style={{
                    width: '30px',
                    marginRight: '15px',
                    marginLeft: '15px',
                    borderRadius: '50%',
                  }}
                  src={
                    props.userData.profile ? props.userData.profile : userImage
                  }
                />
              ) : (
                <UserOutlined />
              )}
              {props.userData && props.userData.first_name}
              <DownOutlined />
            </Button>
          </Dropdown>
        </div>
      </div>
    </HeaderWrapper>
  );
}

const mapReduxStateToProps = (state) => ({
  collapsed: state.Auth.collapsed,
  width: state.Auth.width,
  company: state.Company.company,
  userData: state.Company.userData,
});

export default connect(mapReduxStateToProps, {
  toggleCollapsed,
  setCompany,
  setUserData,
})(withRouter(Topbar));
