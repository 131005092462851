import { ACTION_TYPES, AuthAction } from './Actions';

export interface AuthState {
  collapsed: boolean;
  width: number;
}

const initState: AuthState = {
  collapsed: window.innerWidth > 1220 ? false : true,
  width: window.innerWidth,
};

const Reducer = (
  state: AuthState = initState,
  action: AuthAction,
): AuthState => {
  switch (action.type) {
    case ACTION_TYPES.COLLAPSE_CHANGE:
      return { ...state, collapsed: !state.collapsed };
    default:
      return state;
  }
};

export default Reducer;
