// IMAGES
import {
  EyeInvisibleOutlined,
  GlobalOutlined,
  DownOutlined,
  EyeTwoTone,
} from '@ant-design/icons';
import {
  Button,
  Divider,
  Dropdown,
  Form,
  Input,
  message,
  Typography,
} from 'antd';
import LogoImage from 'Assets/Images/logo.png';
import MSLogoImage from 'Assets/Images/ms-logo.png';
import axios from 'axios';
// CONST
import { URL_DASHBOARD } from 'Helpers/Paths';
import { useAccessTokenItem, useIsLoggedIn } from 'hooks/auth';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { setCompany, setUserData } from 'Redux/Company/Actions';
import logger from 'services/logger';
import Api from '../../../Helpers/ApiHandler';
import { API_PATH } from '../../../Helpers/Paths';
// STYLES
import { LoginBox, LoginWrapper, LogoWrapper, LoginDiv } from './Login.style';
import { useMsal } from '@azure/msal-react';
import { BrowserAuthError } from '@azure/msal-browser';
import i18n from 'i18next';
import { t } from 'translation/i18n';

const API = new Api();
const { Text } = Typography;

function Login(props) {
  const accessTokenItem = useAccessTokenItem();
  const isLoggedIn = useIsLoggedIn();
  const { instance: msalInstance } = useMsal();
  const [msalError, setMsalError] = useState(null);

  const [companyData, setCompanyData] = useState(props.company);

  const menu2 = {
    items: [
      {
        key: '1',
        label: (
          <div
            onClick={() => {
              document.body.dir = 'rtl';
              i18n.changeLanguage('ar');
            }}
          >
            اللغة العربية
          </div>
        ),
      },
      {
        key: '2',
        label: (
          <div
            onClick={() => {
              document.body.dir = 'ltr';
              i18n.changeLanguage('en');
            }}
          >
            English
          </div>
        ),
      },
    ],
  };

  useEffect(() => {
    let params = queryString.parse(props.location.search);

    checkIfAlreadyLoggedIn();
    if (params.ref_id) {
      companyDetail(params.ref_id);
    }
    // eslint-disable-next-line
  }, []);

  // TO CHECK IF ALREADY LOGIN
  function checkIfAlreadyLoggedIn() {
    if (isLoggedIn) props.history.replace(URL_DASHBOARD);
  }

  // TO GET COMPANY DETAILS
  const companyDetail = (ref) => {
    let companyUrl = API_PATH.COMPANY_DETAIL + ref + '/';
    let data = {
      skipAuth: true,
    };
    API.get(companyUrl, data).then((res) => {
      if (res.status === 200) {
        setCompanyData(res.data);
        props.setCompany(res.data);
      }
    });
  };

  // TO CHECK USER LOGIN
  function loginUser(values) {
    // SET YOUR LOGIN INFO HERE
    let payload = {
      data: {
        company_id: companyData ? companyData.id : 0,
        email: values.username,
        password: values.password,
      },
      skipAuth: true,
    };
    API.post(API_PATH.URL_LOGIN_DETAILS, payload).then((res) => {
      if (res.status === 200) {
        if (res.data.auth_token) {
          getProfileData(res);
        } else {
          message.error(t('login.Something went wrong, please try again!'));
        }
      } else {
        message.error(t('login.Username or password wrong'));
      }
    });
  }

  // TO GET USER PROFILE AFTER LOGIN
  function getProfileData(data) {
    let userUrl = API_PATH.URL_USER_DETAILS;
    let headerData = {
      headers: {
        Authorization: 'Token ' + data.data.auth_token,
      },
    };
    axios
      .get(userUrl, headerData)
      .then((res) => {
        if (res.data.is_dashboard_user) {
          props.setUserData(res.data);
          accessTokenItem.setItem(data.data.auth_token);
          props.history.replace(URL_DASHBOARD);
        } else {
          message.error(t('login.Unable to login'));
        }
      })
      .catch(logger.error);
  }

  const azureLogin = () => {
    setMsalError(null);
    msalInstance
      .loginPopup({ prompt: 'select_account', scopes: ['user.read'] })
      .then((res) => {
        let payload = {
          data: {
            company_id: companyData ? companyData.id : 0,
            azure_token: res.accessToken,
          },
        };
        API.post(API_PATH.URL_AZURE_LOGIN_DETAILS, payload).then((res) => {
          if (res.status === 200) {
            if (res.data.auth_token) {
              getProfileData(res);
            } else {
              message.error(t('login.Something went wrong, please try again!'));
            }
          } else {
            message.error(t('login.Username or password wrong'));
          }
        });
      })
      .catch((error) => {
        if (error instanceof BrowserAuthError) {
          setMsalError(error);
        }
      });
  };

  return (
    <LoginDiv>
      <div
        style={{
          display: 'flex',
          width: '100%',
          backgroundColor: '#fff',
          padding: 10,
          justifyContent: 'flex-end',
          boxShadow: 'rgb(204, 204, 204) 6px 0px 10px',
        }}
      >
        <Dropdown menu={menu2}>
          <Button className="dropdown-profile">
            <GlobalOutlined
              style={{
                marginRight: '15px',
                marginLeft: '15px',
                borderRadius: '50%',
              }}
            />
            {t('shared.current-language')}
            <DownOutlined />
          </Button>
        </Dropdown>
      </div>
      <LoginWrapper className="flex f-v-center f-h-center">
        <LoginBox className="box">
          <LogoWrapper>
            <img
              className="img-logo-login"
              src={
                props.company && props.company.logo
                  ? props.company.logo
                  : LogoImage
              }
              alt="Logo"
            />
          </LogoWrapper>
          <Form className="form-container" onFinish={loginUser}>
            <Form.Item
              name="username"
              rules={[
                {
                  required: true,
                  message: t('login.Please input your username!'),
                },
              ]}
            >
              <Input size="large" placeholder={t('shared.Email')} />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: t('login.Please enter your password!'),
                },
              ]}
            >
              <Input.Password
                placeholder={t('user_profile.Password')}
                size="large"
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </Form.Item>
            <div className="button-container">
              <Button
                className="btn"
                type="primary"
                shape="round"
                size="large"
                htmlType="submit"
              >
                {t('login.Login')}
              </Button>
            </div>
          </Form>
          <Divider>{t('login.OR')}</Divider>
          <button className="ms-sign-in" onClick={azureLogin}>
            <img src={MSLogoImage} className="logo" alt="MS_LOGO" />
            <span>{t('login.Sign in with Microsoft')}</span>
          </button>
          {!!msalError && (
            <Text type="danger">Error: {msalError.errorCode}</Text>
          )}
        </LoginBox>
      </LoginWrapper>
    </LoginDiv>
  );
}

const mapReduxStateToProps = (state) => ({
  company: state.Company.company,
});

export default connect(mapReduxStateToProps, {
  setCompany,
  setUserData,
})(withRouter(Login));
