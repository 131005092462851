import React from 'react';
// import ReactDOM from 'react-dom';
import ReactDOM from 'react-dom/client';
import configureSentry from 'services/sentry';
import App from './App';
import * as serviceWorker from './serviceWorker';
// import 'antd/dist/antd.variable.min.css';
// import { ConfigProvider } from 'antd';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import './translation/i18n';

configureSentry();

// Configuration object constructed.
const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_IDENTITY_APP_ID,
  },
};

// create msal PublicClientApplication instance
const publicClientApplication = new PublicClientApplication(msalConfig);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <MsalProvider instance={publicClientApplication}>
    <App />
  </MsalProvider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
