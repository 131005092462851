import Assessment from './Assessment/Reducer';
import Auth from './Auth/Reducer';
import Company from './Company/Reducer';
import General from './General/Reducer';
import ONA from './ONA/reducer';

const reducers = {
  Auth,
  General,
  Company,
  Assessment,
  ONA,
};

export default reducers;
export type Reducers = typeof reducers;
export type ReducersKey = keyof Reducers;
export type State = {
  [key in ReducersKey]: NonNullable<Parameters<Reducers[key]>[0]>;
};
export type Action = Parameters<Reducers[ReducersKey]>[1];
