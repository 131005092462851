import {
  LocalStorageKey,
  localStorageSchemas,
  LocalStorageSchemas,
} from 'config/localStorage';
import { isNil } from 'lodash';
import { GetSchemaType } from 'models/localStorage';
import React from 'react';
import localStorage from 'services/localStorage';

export const useLocalStorageItem = <Key extends LocalStorageKey>(key: Key) => {
  type A = GetSchemaType<LocalStorageSchemas[Key]>;

  const getItem = React.useCallback(() => {
    const localStorageItem = localStorage.getItem(key);

    if (localStorageItem) {
      try {
        const parsedItem = JSON.parse(localStorageItem);

        if (isNil(parsedItem)) {
          return null;
        } else {
          return localStorageSchemas[key].validateSync(parsedItem, {
            strict: false,
          }) as A;
        }
      } catch {
        localStorage.removeItem(key);

        return null;
      }
    } else {
      return null;
    }
  }, [key]);

  const [item, setItem] = React.useState<A | null>(getItem);
  React.useEffect(() => {
    const unsubscribe = localStorage.subscribe({
      key,
      observe: (item) => {
        setItem(item && JSON.parse(item));
      },
    });
    return unsubscribe;
  }, [key]);

  const setLocalStorageItem = React.useCallback(
    (item: A) => {
      localStorage.setItem(key, JSON.stringify(item));
    },
    [key],
  );

  const removeItem = React.useCallback(() => {
    localStorage.removeItem(key);

    setItem(null);
  }, [key]);

  return React.useMemo(
    () => ({
      item,
      setItem: setLocalStorageItem,
      removeItem,
    }),
    [item, removeItem, setLocalStorageItem],
  );
};
