import styled from 'styled-components';

export const LoginWrapper = styled.div`
  height: calc(100vh - 55px);
  width: 100%;
`;

export const LoginDiv = styled.div`
  .dropdown-profile {
    border: none;
    box-shadow: none;
    color: #333333;
  }

  .dropdown-profile > .anticon-user {
    margin-left: 8px;
    margin-right: 8px;
  }
  .dropdown-profile > .anticon-user {
    font-size: 16px;
  }
`;

export const LoginBox = styled.div`
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 20px rgba(92, 111, 139, 0.12);
  padding: 30px;
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .form-container {
    margin: 20px auto 0;
    width: 90%;
  }
  .button-container {
    text-align: center;
    .btn {
      text-transform: uppercase;
      min-width: 125px;
      min-height: 45px;
      background: ${(props) => props.theme.color};
      border-color: ${(props) => props.theme.color};
    }
  }
  .dropdown-profile {
    border: none;
    box-shadow: none;
    color: #333333;
  }

  .dropdown-profile > .anticon-user {
    margin-left: 8px;
    margin-right: 8px;
  }
  .dropdown-profile > .anticon-user {
    font-size: 16px;
  }
  .ms-sign-in {
    display: inline-flex;
    font-family: 'Segoeui Regular', serif;
    font-size: 15px;
    font-weight: 400;
    color: #5e5e5e;
    background-color: #ffffff;
    border: solid #8c8c8c 1px;
    padding: 12px;
    cursor: pointer;
    .logo {
      margin-right: 12px;
    }
  }
`;

export const LogoWrapper = styled.div`
  text-align: center;
  width: 100%;

  .img-logo-login {
    width: 160px;
  }
`;
