import { Company, User } from 'models/company';

export const ACTION_TYPES = {
  ADD_COMPANY: 'ADD_COMPANY',
  ADD_USER_DATA: 'ADD_USER_DATA',
} as const;

export const setCompany = (data: Company) => {
  // SET YOUR LOGIN INFO HERE
  localStorage.setItem('company', JSON.stringify(data));
  return {
    type: ACTION_TYPES.ADD_COMPANY,
    data: data,
  };
};
export type SetCompany = typeof setCompany;
export type SetCompanyAction = ReturnType<SetCompany>;

export const setUserData = (data: User) => {
  // SET YOUR LOGIN USER INFO HERE
  localStorage.setItem('userData', JSON.stringify(data));
  return {
    type: ACTION_TYPES.ADD_USER_DATA,
    data,
  };
};
export type SetUserData = typeof setUserData;
export type SetUserDataAction = ReturnType<SetUserData>;

export type CompanyAction = SetCompanyAction | SetUserDataAction;
