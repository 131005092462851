import {
  CollaborationPayload,
  DateFilterPayload,
  DepartmentsCollaborationData,
  DepartmentsCollaborationPayload,
  DepartmentsData,
  EmailsCollaborationData,
  Employee,
  EngagementData,
  AttritionData,
  EngagementPayload,
  BalancePayload,
  GraphData,
  GraphPayload,
  LocationsData,
  RolesData,
  MeetingsCollaborationData,
  ProductivityData,
  ProductivityPayload,
  ProductivityRankingData,
  ProductivityRankingPayload,
  SearchEmployeesPayload,
  TypeBreakdownData,
  TypeBreakdownPayload,
  WellbeingAveragesData,
  BalanceData,
  WellbeingMetricsData,
  WellbeingPayload,
  WithCompanyId,
  AttritionPayload,
  DivisionsData,
} from 'models/ONA';

export const ONA_ACTIONS = {
  LOAD_ONA_DATA: 'LOAD_ONA_DATA',
  LOAD_ONA_DATA_SUCCESS: 'LOAD_ONA_DATA_SUCCESS',
  LOAD_ONA_DATA_ERROR: 'LOAD_ONA_DATA_ERROR',
  LOAD_ONA_DATA_IS_PENDING: 'LOAD_ONA_DATA_IS_PENDING',
  SET_FILTERS: 'SET_FILTERS',
} as const;

export const ONA_ACTION_SUBTYPES = {
  // LOAD DATA SUBTYPES
  EMAIL_COLLABORATION: 'EMAIL_COLLABORATION',
  MEETING_COLLABORATION: 'MEETING_COLLABORATION',
  DEPARTMENT_COLLABORATION: 'DEPARTMENT_COLLABORATION',
  TYPE_BREAKDOWN: 'TYPE_BREAKDOWN',
  GRAPH: 'GRAPH',
  ENGAGEMENT: 'ENGAGEMENT',
  ATTRITION: 'ATTRITION',
  PRODUCTIVITY: 'PRODUCTIVITY',
  BALANCE: 'BALANCE',
  WELLBEING_METRICS: 'WELLBEING_METRICS',
  WELLBEING_AVERAGES: 'WELLBEING_AVERAGES',
  DEPARTMENTS: 'DEPARTMENTS',
  DIVISIONS: 'DIVISIONS',
  LOCATIONS: 'LOCATIONS',
  ROLES: 'ROLES',
  PRODUCTIVITY_RANKING: 'PRODUCTIVITY_RANKING',
  // FILTERS SUBTYPES
  DEPARTMENT: 'DEPARTMENT',
  DIVISION: 'DIVISION',
  LOCATION: 'LOCATION',
  ROLE: 'ROLE',
  USER: 'USER',
  DATE: 'DATE',
  // SEARCH EMPLOYEES
  SEARCH_EMPLOYEES: 'SEARCH_EMPLOYEES',
} as const;

export const setDateFilter = (data: DateFilterPayload) => ({
  type: ONA_ACTIONS.SET_FILTERS,
  subtype: ONA_ACTION_SUBTYPES.DATE,
  data,
});
export type SetDateFilter = typeof setDateFilter;
export type SetDateFilterAction = ReturnType<SetDateFilter>;

export const setUserFilter = (data: { option: Employee | null }) => ({
  type: ONA_ACTIONS.SET_FILTERS,
  subtype: ONA_ACTION_SUBTYPES.USER,
  data,
});
export type SetUserFilter = typeof setUserFilter;
export type SetUserFilterAction = ReturnType<SetUserFilter>;

export const setRoleFilter = (data: { option: RolesData }) => ({
  type: ONA_ACTIONS.SET_FILTERS,
  subtype: ONA_ACTION_SUBTYPES.ROLE,
  data,
});
export type SetRoleFilter = typeof setRoleFilter;
export type SetRoleFilterAction = ReturnType<SetRoleFilter>;

export const setLocationFilter = (data: { option: LocationsData }) => ({
  type: ONA_ACTIONS.SET_FILTERS,
  subtype: ONA_ACTION_SUBTYPES.LOCATION,
  data,
});
export type SetLocationFilter = typeof setLocationFilter;
export type SetLocationFilterAction = ReturnType<SetLocationFilter>;

export const setDepartmentFilter = (data: { option: DepartmentsData }) => ({
  type: ONA_ACTIONS.SET_FILTERS,
  subtype: ONA_ACTION_SUBTYPES.DEPARTMENT,
  data,
});
export type SetDepartmentFilter = typeof setDepartmentFilter;
export type SetDepartmentFilterAction = ReturnType<SetDepartmentFilter>;

export const setDivisionFilter = (data: { option: DivisionsData }) => ({
  type: ONA_ACTIONS.SET_FILTERS,
  subtype: ONA_ACTION_SUBTYPES.DIVISION,
  data,
});
export type SetDivisionFilter = typeof setDivisionFilter;
export type SetDivisionFilterAction = ReturnType<SetDivisionFilter>;

export const getRoles = (data: WithCompanyId) => ({
  type: ONA_ACTIONS.LOAD_ONA_DATA,
  subtype: ONA_ACTION_SUBTYPES.ROLES,
  data,
});
export type GetRoles = typeof getRoles;
export type GetRolesAction = ReturnType<GetRoles>;

export const getLocations = (data: WithCompanyId) => ({
  type: ONA_ACTIONS.LOAD_ONA_DATA,
  subtype: ONA_ACTION_SUBTYPES.LOCATIONS,
  data,
});
export type GetLocations = typeof getLocations;
export type GetLocationsAction = ReturnType<GetLocations>;

export const getDepartments = (data: WithCompanyId) => ({
  type: ONA_ACTIONS.LOAD_ONA_DATA,
  subtype: ONA_ACTION_SUBTYPES.DEPARTMENTS,
  data,
});
export type GetDepartments = typeof getDepartments;
export type GetDepartmentsAction = ReturnType<GetDepartments>;

export const getDivisions = (data: WithCompanyId) => ({
  type: ONA_ACTIONS.LOAD_ONA_DATA,
  subtype: ONA_ACTION_SUBTYPES.DIVISIONS,
  data,
});
export type GetDivisions = typeof getDivisions;
export type GetDivisionsAction = ReturnType<GetDivisions>;

export const getEmailCollaborationData = (data: CollaborationPayload) => ({
  type: ONA_ACTIONS.LOAD_ONA_DATA,
  subtype: ONA_ACTION_SUBTYPES.EMAIL_COLLABORATION,
  data,
});
export type GetEmailCollaborationData = typeof getEmailCollaborationData;
export type GetEmailCollaborationDataAction =
  ReturnType<GetEmailCollaborationData>;

export const getMeetingCollaborationData = (data: CollaborationPayload) => ({
  type: ONA_ACTIONS.LOAD_ONA_DATA,
  subtype: ONA_ACTION_SUBTYPES.MEETING_COLLABORATION,
  data,
});
export type GetMeetingCollaborationData = typeof getMeetingCollaborationData;
export type GetMeetingCollaborationDataAction =
  ReturnType<GetMeetingCollaborationData>;

export const getDepartmentsCollaborationData = (
  data: DepartmentsCollaborationPayload,
) => ({
  type: ONA_ACTIONS.LOAD_ONA_DATA,
  subtype: ONA_ACTION_SUBTYPES.DEPARTMENT_COLLABORATION,
  data,
});
export type GetDepartmentsCollaborationData =
  typeof getDepartmentsCollaborationData;
export type GetDepartmentsCollaborationDataAction =
  ReturnType<GetDepartmentsCollaborationData>;

export const getTypeBreakdownData = (data: TypeBreakdownPayload) => ({
  type: ONA_ACTIONS.LOAD_ONA_DATA,
  subtype: ONA_ACTION_SUBTYPES.TYPE_BREAKDOWN,
  data,
});
export type GetTypeBreakdownData = typeof getTypeBreakdownData;
export type GetTypeBreakdownDataAction = ReturnType<GetTypeBreakdownData>;

export const getGraphData = (data: GraphPayload) => ({
  type: ONA_ACTIONS.LOAD_ONA_DATA,
  subtype: ONA_ACTION_SUBTYPES.GRAPH,
  data,
});
export type GetGraphData = typeof getGraphData;
export type GetGraphDataAction = ReturnType<GetGraphData>;

export const getEngagementData = (data: EngagementPayload) => ({
  type: ONA_ACTIONS.LOAD_ONA_DATA,
  subtype: ONA_ACTION_SUBTYPES.ENGAGEMENT,
  data,
});
export type GetEngagementData = typeof getEngagementData;
export type GetEngagementDataAction = ReturnType<GetEngagementData>;

export const getAttritionData = (data: AttritionPayload) => ({
  type: ONA_ACTIONS.LOAD_ONA_DATA,
  subtype: ONA_ACTION_SUBTYPES.ATTRITION,
  data,
});
export type getAttritionDataType = typeof getAttritionData;
export type GetAttritionDataAction = ReturnType<getAttritionDataType>;

export const getProductivityData = (data: ProductivityPayload) => ({
  type: ONA_ACTIONS.LOAD_ONA_DATA,
  subtype: ONA_ACTION_SUBTYPES.PRODUCTIVITY,
  data,
});
export type GetProductivityData = typeof getProductivityData;
export type GetProductivityDataAction = ReturnType<GetProductivityData>;

export const getBalanceData = (data: BalancePayload) => ({
  type: ONA_ACTIONS.LOAD_ONA_DATA,
  subtype: ONA_ACTION_SUBTYPES.BALANCE,
  data,
});
export type GetBalanceData = typeof getBalanceData;
export type GetBalanceDataAction = ReturnType<GetBalanceData>;

export const getWellbeingMetricsData = (data: WellbeingPayload) => ({
  type: ONA_ACTIONS.LOAD_ONA_DATA,
  subtype: ONA_ACTION_SUBTYPES.WELLBEING_METRICS,
  data,
});
export type GetWellbeingMetricsData = typeof getWellbeingMetricsData;
export type GetWellbeingMetricsDataAction = ReturnType<GetWellbeingMetricsData>;

export const getWellbeingAveragesData = (data: WellbeingPayload) => ({
  type: ONA_ACTIONS.LOAD_ONA_DATA,
  subtype: ONA_ACTION_SUBTYPES.WELLBEING_AVERAGES,
  data,
});
export type GetWellbeingAveragesData = typeof getWellbeingAveragesData;
export type GetWellbeingAveragesDataAction =
  ReturnType<GetWellbeingAveragesData>;

export const getProductivityRankingData = (
  data: ProductivityRankingPayload,
) => ({
  type: ONA_ACTIONS.LOAD_ONA_DATA,
  subtype: ONA_ACTION_SUBTYPES.PRODUCTIVITY_RANKING,
  data,
});
export type GetProductivityRankingData = typeof getProductivityRankingData;
export type GetProductivityRankingDataAction =
  ReturnType<GetProductivityRankingData>;

export const searchEmployees = (data: SearchEmployeesPayload) => ({
  type: ONA_ACTIONS.LOAD_ONA_DATA,
  subtype: ONA_ACTION_SUBTYPES.SEARCH_EMPLOYEES,
  data,
});
export type SearchEmployees = typeof searchEmployees;
export type SearchEmployeesAction = ReturnType<SearchEmployees>;

export type ONASagaInputAction =
  | GetEmailCollaborationDataAction
  | GetMeetingCollaborationDataAction
  | GetDepartmentsCollaborationDataAction
  | GetTypeBreakdownDataAction
  | GetGraphDataAction
  | GetEngagementDataAction
  | GetAttritionDataAction
  | GetProductivityDataAction
  | GetBalanceDataAction
  | GetWellbeingMetricsDataAction
  | GetWellbeingAveragesDataAction
  | GetDepartmentsAction
  | GetDivisionsAction
  | GetLocationsAction
  | GetRolesAction
  | GetProductivityRankingDataAction
  | SearchEmployeesAction;

export type ONASagaLoadingOutputAction =
  | {
      type: typeof ONA_ACTIONS.LOAD_ONA_DATA_IS_PENDING;
      subtype: typeof ONA_ACTION_SUBTYPES.EMAIL_COLLABORATION;
    }
  | {
      type: typeof ONA_ACTIONS.LOAD_ONA_DATA_IS_PENDING;
      subtype: typeof ONA_ACTION_SUBTYPES.MEETING_COLLABORATION;
    }
  | {
      type: typeof ONA_ACTIONS.LOAD_ONA_DATA_IS_PENDING;
      subtype: typeof ONA_ACTION_SUBTYPES.DEPARTMENT_COLLABORATION;
    }
  | {
      type: typeof ONA_ACTIONS.LOAD_ONA_DATA_IS_PENDING;
      subtype: typeof ONA_ACTION_SUBTYPES.TYPE_BREAKDOWN;
    }
  | {
      type: typeof ONA_ACTIONS.LOAD_ONA_DATA_IS_PENDING;
      subtype: typeof ONA_ACTION_SUBTYPES.GRAPH;
    }
  | {
      type: typeof ONA_ACTIONS.LOAD_ONA_DATA_IS_PENDING;
      subtype: typeof ONA_ACTION_SUBTYPES.ENGAGEMENT;
    }
  | {
      type: typeof ONA_ACTIONS.LOAD_ONA_DATA_IS_PENDING;
      subtype: typeof ONA_ACTION_SUBTYPES.ATTRITION;
    }
  | {
      type: typeof ONA_ACTIONS.LOAD_ONA_DATA_IS_PENDING;
      subtype: typeof ONA_ACTION_SUBTYPES.PRODUCTIVITY;
    }
  | {
      type: typeof ONA_ACTIONS.LOAD_ONA_DATA_IS_PENDING;
      subtype: typeof ONA_ACTION_SUBTYPES.BALANCE;
    }
  | {
      type: typeof ONA_ACTIONS.LOAD_ONA_DATA_IS_PENDING;
      subtype: typeof ONA_ACTION_SUBTYPES.WELLBEING_METRICS;
    }
  | {
      type: typeof ONA_ACTIONS.LOAD_ONA_DATA_IS_PENDING;
      subtype: typeof ONA_ACTION_SUBTYPES.WELLBEING_AVERAGES;
    }
  | {
      type: typeof ONA_ACTIONS.LOAD_ONA_DATA_IS_PENDING;
      subtype: typeof ONA_ACTION_SUBTYPES.DEPARTMENTS;
    }
  | {
      type: typeof ONA_ACTIONS.LOAD_ONA_DATA_IS_PENDING;
      subtype: typeof ONA_ACTION_SUBTYPES.DIVISIONS;
    }
  | {
      type: typeof ONA_ACTIONS.LOAD_ONA_DATA_IS_PENDING;
      subtype: typeof ONA_ACTION_SUBTYPES.LOCATIONS;
    }
  | {
      type: typeof ONA_ACTIONS.LOAD_ONA_DATA_IS_PENDING;
      subtype: typeof ONA_ACTION_SUBTYPES.PRODUCTIVITY_RANKING;
    }
  | {
      type: typeof ONA_ACTIONS.LOAD_ONA_DATA_IS_PENDING;
      subtype: typeof ONA_ACTION_SUBTYPES.SEARCH_EMPLOYEES;
    };

export type ONASagaErrorOutputAction =
  | {
      type: typeof ONA_ACTIONS.LOAD_ONA_DATA_ERROR;
      subtype: typeof ONA_ACTION_SUBTYPES.EMAIL_COLLABORATION;
      message: string;
    }
  | {
      type: typeof ONA_ACTIONS.LOAD_ONA_DATA_ERROR;
      subtype: typeof ONA_ACTION_SUBTYPES.MEETING_COLLABORATION;
      message: string;
    }
  | {
      type: typeof ONA_ACTIONS.LOAD_ONA_DATA_ERROR;
      subtype: typeof ONA_ACTION_SUBTYPES.DEPARTMENT_COLLABORATION;
      message: string;
    }
  | {
      type: typeof ONA_ACTIONS.LOAD_ONA_DATA_ERROR;
      subtype: typeof ONA_ACTION_SUBTYPES.TYPE_BREAKDOWN;
      message: string;
    }
  | {
      type: typeof ONA_ACTIONS.LOAD_ONA_DATA_ERROR;
      subtype: typeof ONA_ACTION_SUBTYPES.GRAPH;
      message: string;
    }
  | {
      type: typeof ONA_ACTIONS.LOAD_ONA_DATA_ERROR;
      subtype: typeof ONA_ACTION_SUBTYPES.ENGAGEMENT;
      message: string;
    }
  | {
      type: typeof ONA_ACTIONS.LOAD_ONA_DATA_ERROR;
      subtype: typeof ONA_ACTION_SUBTYPES.ATTRITION;
      message: string;
    }
  | {
      type: typeof ONA_ACTIONS.LOAD_ONA_DATA_ERROR;
      subtype: typeof ONA_ACTION_SUBTYPES.PRODUCTIVITY;
      message: string;
    }
  | {
      type: typeof ONA_ACTIONS.LOAD_ONA_DATA_ERROR;
      subtype: typeof ONA_ACTION_SUBTYPES.BALANCE;
      message: string;
    }
  | {
      type: typeof ONA_ACTIONS.LOAD_ONA_DATA_ERROR;
      subtype: typeof ONA_ACTION_SUBTYPES.WELLBEING_METRICS;
      message: string;
    }
  | {
      type: typeof ONA_ACTIONS.LOAD_ONA_DATA_ERROR;
      subtype: typeof ONA_ACTION_SUBTYPES.WELLBEING_AVERAGES;
      message: string;
    }
  | {
      type: typeof ONA_ACTIONS.LOAD_ONA_DATA_ERROR;
      subtype: typeof ONA_ACTION_SUBTYPES.DEPARTMENTS;
      message: string;
    }
  | {
      type: typeof ONA_ACTIONS.LOAD_ONA_DATA_ERROR;
      subtype: typeof ONA_ACTION_SUBTYPES.DIVISIONS;
      message: string;
    }
  | {
      type: typeof ONA_ACTIONS.LOAD_ONA_DATA_ERROR;
      subtype: typeof ONA_ACTION_SUBTYPES.LOCATIONS;
      message: string;
    }
  | {
      type: typeof ONA_ACTIONS.LOAD_ONA_DATA_ERROR;
      subtype: typeof ONA_ACTION_SUBTYPES.PRODUCTIVITY_RANKING;
      message: string;
    }
  | {
      type: typeof ONA_ACTIONS.LOAD_ONA_DATA_ERROR;
      subtype: typeof ONA_ACTION_SUBTYPES.SEARCH_EMPLOYEES;
      message: string;
    };

export type ONASagaSuccessOutputAction =
  | {
      type: typeof ONA_ACTIONS.LOAD_ONA_DATA_SUCCESS;
      subtype: typeof ONA_ACTION_SUBTYPES.EMAIL_COLLABORATION;
      data: EmailsCollaborationData;
    }
  | {
      type: typeof ONA_ACTIONS.LOAD_ONA_DATA_SUCCESS;
      subtype: typeof ONA_ACTION_SUBTYPES.MEETING_COLLABORATION;
      data: MeetingsCollaborationData;
    }
  | {
      type: typeof ONA_ACTIONS.LOAD_ONA_DATA_SUCCESS;
      subtype: typeof ONA_ACTION_SUBTYPES.DEPARTMENT_COLLABORATION;
      data: DepartmentsCollaborationData;
    }
  | {
      type: typeof ONA_ACTIONS.LOAD_ONA_DATA_SUCCESS;
      subtype: typeof ONA_ACTION_SUBTYPES.TYPE_BREAKDOWN;
      data: TypeBreakdownData;
    }
  | {
      type: typeof ONA_ACTIONS.LOAD_ONA_DATA_SUCCESS;
      subtype: typeof ONA_ACTION_SUBTYPES.GRAPH;
      data: GraphData;
    }
  | {
      type: typeof ONA_ACTIONS.LOAD_ONA_DATA_SUCCESS;
      subtype: typeof ONA_ACTION_SUBTYPES.ENGAGEMENT;
      data: EngagementData;
    }
  | {
      type: typeof ONA_ACTIONS.LOAD_ONA_DATA_SUCCESS;
      subtype: typeof ONA_ACTION_SUBTYPES.ATTRITION;
      data: AttritionData;
    }
  | {
      type: typeof ONA_ACTIONS.LOAD_ONA_DATA_SUCCESS;
      subtype: typeof ONA_ACTION_SUBTYPES.PRODUCTIVITY;
      data: ProductivityData;
    }
  | {
      type: typeof ONA_ACTIONS.LOAD_ONA_DATA_SUCCESS;
      subtype: typeof ONA_ACTION_SUBTYPES.BALANCE;
      data: BalanceData;
    }
  | {
      type: typeof ONA_ACTIONS.LOAD_ONA_DATA_SUCCESS;
      subtype: typeof ONA_ACTION_SUBTYPES.WELLBEING_METRICS;
      data: WellbeingMetricsData;
    }
  | {
      type: typeof ONA_ACTIONS.LOAD_ONA_DATA_SUCCESS;
      subtype: typeof ONA_ACTION_SUBTYPES.WELLBEING_AVERAGES;
      data: WellbeingAveragesData;
    }
  | {
      type: typeof ONA_ACTIONS.LOAD_ONA_DATA_SUCCESS;
      subtype: typeof ONA_ACTION_SUBTYPES.DEPARTMENTS;
      data: DepartmentsData;
    }
  | {
      type: typeof ONA_ACTIONS.LOAD_ONA_DATA_SUCCESS;
      subtype: typeof ONA_ACTION_SUBTYPES.DIVISIONS;
      data: DivisionsData;
    }
  | {
      type: typeof ONA_ACTIONS.LOAD_ONA_DATA_SUCCESS;
      subtype: typeof ONA_ACTION_SUBTYPES.LOCATIONS;
      data: LocationsData;
    }
  | {
      type: typeof ONA_ACTIONS.LOAD_ONA_DATA_SUCCESS;
      subtype: typeof ONA_ACTION_SUBTYPES.ROLES;
      data: RolesData;
    }
  | {
      type: typeof ONA_ACTIONS.LOAD_ONA_DATA_SUCCESS;
      subtype: typeof ONA_ACTION_SUBTYPES.PRODUCTIVITY_RANKING;
      data: ProductivityRankingData;
    }
  | {
      type: typeof ONA_ACTIONS.LOAD_ONA_DATA_SUCCESS;
      subtype: typeof ONA_ACTION_SUBTYPES.SEARCH_EMPLOYEES;
      data: Employee[];
    };

export type ONASagaOutputAction =
  | ONASagaErrorOutputAction
  | ONASagaLoadingOutputAction
  | ONASagaSuccessOutputAction;

export type ONAAction =
  | ONASagaOutputAction
  | SetDateFilterAction
  | SetUserFilterAction
  | SetRoleFilterAction
  | SetLocationFilterAction
  | SetDepartmentFilterAction
  | SetDivisionFilterAction
  | GetRolesAction
  | GetLocationsAction
  | GetDepartmentsAction
  | GetDivisionsAction
  | GetEmailCollaborationDataAction
  | GetMeetingCollaborationDataAction
  | GetDepartmentsCollaborationDataAction
  | GetTypeBreakdownDataAction
  | GetGraphDataAction
  | GetEngagementDataAction
  | GetAttritionDataAction
  | GetBalanceDataAction
  | GetWellbeingMetricsDataAction
  | GetWellbeingAveragesDataAction
  | GetProductivityRankingDataAction
  | SearchEmployeesAction;
