import {
  applyMiddleware,
  combineReducers,
  createStore,
  Middleware,
} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import createSagaMiddleware from 'redux-saga';
import onaSaga from './ONA/sagas';
import reducers from './Reducers';

const sagaMiddleware = createSagaMiddleware();

const middleware: Middleware[] = [sagaMiddleware];

const store = createStore(
  combineReducers({
    ...reducers,
  }),
  composeWithDevTools(applyMiddleware(...middleware)),
);

sagaMiddleware.run(onaSaga);

export { store };
