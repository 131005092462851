const handleUnknownError = (error: unknown) =>
  console.error(`Unknown error: ${error}`);

const hasMessage = (error: unknown): error is Pick<Error, 'message'> => {
  return typeof error === 'object' && error !== null && 'message' in error!;
};

/**
 *
 * TODO replace console.error with error logging service
 *
 * @param error Of type unknown for backwards compatibility
 */
const logError = (error: unknown) => {
  if (error) {
    if (hasMessage(error)) {
      console.error(error.message);
    } else if (typeof error === 'string') {
      console.error(error);
    } else {
      handleUnknownError(error);
    }
  } else {
    handleUnknownError(error);
  }
};

const Main = { error: logError };

export default Main;
