// THEME
import { Layout } from 'antd';
import styled from 'styled-components';
import { DIMENSIONS, responsive } from 'Styles/Constants';

const { Header } = Layout;

export const LogoWrapper = styled.div`
  height: ${DIMENSIONS.HEADER}px;

  .img-logo {
    max-height: 45px;
    max-width: 200px;
    object-fit: contain;
  }
`;
LogoWrapper.displayName = 'LogoWrapper';

export const HeaderWrapper = styled(Header)`
  background: #fff;
  box-shadow: 6px 0px 10px #ccc;
  position: fixed;
  width: 100%;
  z-index: 1000;

  .menu-icon {
    cursor: pointer;
    display: none;
  }
  .dropdown-profile {
    border: none;
    box-shadow: none;
    color: #333333;
  }

  .dropdown-profile > .anticon-user {
    margin-left: 8px;
    margin-right: 8px;
  }
  .dropdown-profile > .anticon-user {
    font-size: 16px;
  }

  &.header {
    padding: 0 20px;
  }

  @media only screen and (max-width: 1230px) {
    .menu-icon {
      display: block;
    }
  }
  .header {
    position: fixed;
    /* width: ${(props) =>
      !props.collapsed
        ? 'calc(100% - 200px) !important'
        : 'calc(100% - 80px) !important'}; */
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: space-between;
    /* padding: 0 31px 0 265px; */

    /* ${responsive.DESKTOP`
            width  : ${(props) =>
              !props.collapsed
                ? 'calc(100% - 200px) !important'
                : 'calc(100% - 80px) !important'}
        `}

    ${responsive.PHABLET`
            width  : ${(props) =>
              !props.collapsed
                ? 'calc(100% - 200px) !important'
                : '100% !important'}
        `} */
  }

  @media only screen and (max-width: 767px) {
    .header {
      /* padding:0px 15px !important; */
    }
  }
  @media only screen and (max-width: 1219px) {
    .header {
      /* padding:0 31px 0 109px; */
    }
  }
`;

export const CommonPagesHeaderWrapper = styled(Header)`
  background: #fff;
  box-shadow: 6px 0px 10px #ccc;
  position: fixed;
  width: 100%;
  z-index: 2;

  .header {
    float: left;
    width: 100%;
  }
`;
