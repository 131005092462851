import { Company, User } from 'models/company';
import { ACTION_TYPES, CompanyAction } from './Actions';

export interface CompanyState {
  assessmentData: string;
  company: Company | null;
  userData: User | null;
}

const companyData = localStorage.getItem('company');
const userData = localStorage.getItem('userData');

const initState = {
  assessmentData: '',
  company: companyData && JSON.parse(companyData),
  userData: userData && JSON.parse(userData),
};

const Reducer = (
  state: CompanyState = initState,
  action: CompanyAction,
): CompanyState => {
  switch (action.type) {
    case ACTION_TYPES.ADD_COMPANY:
      return {
        ...state,
        company: action.data,
      };
    case ACTION_TYPES.ADD_USER_DATA:
      return {
        ...state,
        userData: action.data,
      };

    default:
      return state;
  }
};

export default Reducer;
