import { useChatbotTheme } from 'hooks/auth';
import { scrollChat } from './utils';

export const CustomBotMessage = ({
  previousStep,
  step,
  triggerNextStep,
  steps,
  CustomMessage = '',
}) => {
  const chatbotTheme = useChatbotTheme();
  scrollChat();
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
      }}
    >
      <div
        style={{
          fontSize: 14,
          textAlign: 'start',
          color: chatbotTheme.headerBgColor,
          padding: 5,
        }}
      >
        Sara
      </div>
      <div
        style={{
          maxWidth: '70%',
          minWidth: '5%',
          borderRadius: '10px 10px 10px 0px',
          backgroundColor: chatbotTheme.botBubbleColor,
          padding: 12,
          fontSize: 14,
        }}
      >
        {CustomMessage}
      </div>
    </div>
  );
};
