export const ACTION_TYPES = {
  TOAST_MESSAGE_ENABLE: 'TOAST_MESSAGE_ENABLE',
  TOAST_MESSAGE_DISABLE: 'TOAST_MESSAGE_DISABLE',
  SET_PAGE_NUMBER: 'SET_PAGE_NUMBER',
} as const;

export const showToast = (message?: string) => {
  return {
    type: ACTION_TYPES.TOAST_MESSAGE_ENABLE,
    message: message || '',
  };
};
export type ShowToast = typeof showToast;
export type ShowToastAction = ReturnType<ShowToast>;

export const hideToast = () => {
  return {
    type: ACTION_TYPES.TOAST_MESSAGE_DISABLE,
  };
};
export type HideToast = typeof hideToast;
export type HideToastAction = ReturnType<HideToast>;

export const setPageNumber = (pageNumber: number) => {
  return {
    type: ACTION_TYPES.SET_PAGE_NUMBER,
    pageNumber,
  };
};
export type SetPageNumber = typeof setPageNumber;
export type SetPageNumberAction = ReturnType<SetPageNumber>;

export type GeneralAction =
  | ShowToastAction
  | HideToastAction
  | SetPageNumberAction;
