import { CustomBotMessage } from './CustomBotMessage';
import { CustomMessage } from './CustomMessage';
import { CustomOptions } from './CustomOptions';
import { CustomSelected } from './CustomSelected';
import { CustomStep } from './CustomStep';

export const steps = ({ userData }) => {
  return [
    {
      id: '0',
      component: (
        <CustomBotMessage
          CustomMessage={
            'Hi, ' +
            (userData ? userData.full_name + ', ' : '') +
            "I am Sara!\nI'm here to help you navigate. How can I help you today? 😊 "
          }
        />
      ),
      trigger: '1',
    },
    {
      id: '1',
      message: 'How can I help you today? 😊',
      trigger: '1.0.1',
    },
    {
      id: '1.0',
      message: 'Here are some questions that you can ask me:',
      trigger: '1.0.1',
    },
    {
      id: '1.0.1',
      component: (
        <CustomOptions
          customValue={[
            'Who is the most productive employee?',
            'How can I use the dashboard?',
            'What can you tell me about work life balance?',
          ]}
        />
      ),
      waitAction: true,
    },
    {
      id: '1.1',
      user: true,
      trigger: '1.1.1',
    },
    {
      id: '1.1.1',
      component: <CustomMessage />,
      asMessage: false,
      trigger: '1.1.2',
    },
    {
      id: '1.1.2',
      component: <CustomStep />,
      asMessage: false,
    },
    {
      id: '1.1.3',
      component: <CustomOptions />,
      waitAction: true,
    },
    {
      id: '1.1.4',
      component: <CustomSelected />,
      trigger: '1.1.2',
    },
    {
      id: '1.2',
      message: "I'm sorry, we are still waiting for Jobin to develop the API",
      trigger: '5',
    },
    {
      id: '1.2.0',
      message: 'Hi, What Should I call you?',
      trigger: '1.2.1',
    },
    {
      id: '1.2.1',
      user: true,
      trigger: '3',
    },
    {
      id: '2',
      user: true,
      trigger: '3',
    },
    {
      id: '3',
      message: 'Hi {previousValue},',
      trigger: '4',
    },
    {
      id: '4',
      message: 'nice to meet you!',
      trigger: '5',
    },
    {
      id: '5',
      message: 'can I ask you a favor?',
      trigger: '6',
    },
    {
      id: '6',
      options: [
        { value: 1, label: 'Yes, Sure', trigger: '7.1' },
        { value: 2, label: 'No!', trigger: '8.1' },
      ],
    },
    {
      id: '7.1',
      message: 'Thank you 😍',
      trigger: '7',
    },
    {
      id: '7',
      message: 'tell me your name again, please!',
      trigger: '2',
    },
    {
      id: '8.1',
      message: 'I will tell Mr. Valabji that you was not cooperative 😡',
      trigger: '8.2',
    },
    {
      id: '8.2',
      message:
        "I'm now erasing my memory, I don't want to know you any more...",
      trigger: '8',
    },
    {
      id: '8',
      message: 'Well, then tell me your name again, please!',
      trigger: '2',
    },
  ];
};
