import { ACTION_TYPES, GeneralAction } from './Actions';

export interface GeneralState {
  toast: {
    enable: boolean;
    message: string;
  };
  pageNumber: number;
}

const initState: GeneralState = {
  toast: {
    enable: false,
    message: '',
  },
  pageNumber: 1,
};

const Reducer = (
  state: GeneralState = initState,
  action: GeneralAction,
): GeneralState => {
  switch (action.type) {
    case ACTION_TYPES.TOAST_MESSAGE_ENABLE:
      return {
        ...state,
        toast: {
          enable: true,
          message: action.message,
        },
      };

    case ACTION_TYPES.TOAST_MESSAGE_DISABLE:
      return {
        ...state,
        toast: initState.toast,
      };

    case ACTION_TYPES.SET_PAGE_NUMBER:
      return {
        ...state,
        pageNumber: action.pageNumber,
      };

    default:
      return state;
  }
};

export default Reducer;
