import {
  AsyncState,
  EmployeeNode,
  EmployeeData,
  GraphGroup,
  GraphLink,
  WithCurrentFilters,
  GraphState,
} from 'models/ONA';
import { createSelector } from 'reselect';
import {
  GetGraphDataAction,
  ONAAction,
  ONASagaSuccessOutputAction,
  ONA_ACTIONS,
  ONA_ACTION_SUBTYPES,
} from './actions';

export type ONAAsyncState<
  SuccessOutputAction extends Exclude<
    ONASagaSuccessOutputAction,
    GetGraphDataAction
  >,
> = {
  [key in ONASagaSuccessOutputAction['subtype']]: AsyncState<
    SuccessOutputAction extends { subtype: key }
      ? SuccessOutputAction['data'] | null
      : never
  >;
} & {
  [key in GetGraphDataAction['subtype']]: AsyncState<GraphState<
    EmployeeNode,
    GraphLink
  > | null>;
};

export type ONAState = WithCurrentFilters &
  ONAAsyncState<ONASagaSuccessOutputAction>;

const COLORS = [
  '#007d7d',
  '#4d4dff',
  '#e6006b',
  '#000000',
  '#007d00',
  '#ffc999',
  '#99ffff',
  '#ffff99',
  '#00e600',
  '#bfbfbf',
  '#00e6e6',
  '#6b00e6',
  '#00007d',
  '#7d007d',
  '#ff4da0',
  '#e6e600',
  '#4d4d4d',
  '#e67777',
  '#99ff99',
  '#7d3a00',
  '#7d7d00',
  '#ff9999',
  '#e60000',
  '#c999ff',
  '#ff99ff',
  '#e66b00',
  '#7777e6',
  '#7d0000',
  '#e600e6',
  '#0000e6',
];
const NEUTRAL_COLOR = '#CACACA';
const MIN_NODE_SIZE = 10;
const MAX_NODE_SIZE = 50;

const processEmployeeGraphData = (data: EmployeeData[]) => {
  const groups: GraphGroup[] = Array.from(
    new Set(data.map((e) => e.group)),
  ).map((group, index) => {
    index = index % COLORS.length;
    return {
      name: group,
      color: group.toLowerCase() === 'other' ? NEUTRAL_COLOR : COLORS[index],
    };
  });
  const ranks = Array.from(new Set(data.map(({ rank }) => rank)));
  const maxRank = ranks.reduce((a, b) => Math.max(a, b), 0);
  const minRank = ranks.reduce((a, b) => Math.min(a, b), maxRank);
  const nodes: EmployeeNode[] = [];
  let links: GraphLink[] = [];
  let linkAvailabilityLookup = new Map();

  data
    .sort((a, b) => a.rank - b.rank)
    .forEach(({ employee, connections, rank, group }, index, array) => {
      nodes.push({
        ...employee,
        id: employee.email,
        title: `${employee.name} ${employee.surname}`,
        label: employee.name,
        group,
        size:
          minRank !== maxRank
            ? ((rank - minRank) * (MAX_NODE_SIZE - MIN_NODE_SIZE)) /
                (maxRank - minRank) +
              MIN_NODE_SIZE
            : MAX_NODE_SIZE,
        // x: 100 + 80 * (index % 10),
        // y: 50 + 80 * Math.floor(index / 10),
        // x: 500 + 6 * (index + 22) * Math.cos((index + 22) / 4),
        // y: 200 + 4 * (index + 22) * Math.sin((index + 22) / 4),
        rank,
      });
      links = links.concat(
        connections
          .filter(
            (connection) =>
              array.find((d) => d.employee.email === connection) &&
              !linkAvailabilityLookup.get(`${connection}-${employee.email}`),
          )
          .map((connection, index) => {
            linkAvailabilityLookup.set(`${employee.email}-${connection}`, true);
            return {
              from: employee.email,
              to: connection,
            };
          }),
      );
    });
  return { nodes, links, groups };
};

const initialState = Object.values(ONA_ACTION_SUBTYPES).reduce(
  (a, subtype) => ({
    ...a,
    [subtype]: {
      loading: false,
      loaded: false,
      isPending: false,
      error: null,
      data: null,
    },
  }),
  {
    currentFilters: {
      DEPARTMENT: null,
      DIVISION: null,
      LOCATION: null,
      USER: null,
      START_DATE: null,
      END_DATE: null,
      INTERVAL: null,
    },
  } as ONAState,
);

const ONA = (state: ONAState = initialState, action: ONAAction): ONAState => {
  switch (action.type) {
    case ONA_ACTIONS.SET_FILTERS:
      switch (action.subtype) {
        case ONA_ACTION_SUBTYPES.DATE:
          return {
            ...initialState,
            [ONA_ACTION_SUBTYPES.DEPARTMENTS]:
              state[ONA_ACTION_SUBTYPES.DEPARTMENTS],
            [ONA_ACTION_SUBTYPES.DIVISIONS]:
              state[ONA_ACTION_SUBTYPES.DIVISIONS],
            [ONA_ACTION_SUBTYPES.LOCATIONS]:
              state[ONA_ACTION_SUBTYPES.LOCATIONS],
            [ONA_ACTION_SUBTYPES.ROLES]: state[ONA_ACTION_SUBTYPES.ROLES],
            currentFilters: {
              ...state.currentFilters,
              START_DATE: action.data.startDate,
              END_DATE: action.data.endDate,
              INTERVAL: action.data.interval,
            },
          };
        default:
          return {
            ...initialState,
            [ONA_ACTION_SUBTYPES.DEPARTMENTS]:
              state[ONA_ACTION_SUBTYPES.DEPARTMENTS],
            [ONA_ACTION_SUBTYPES.DIVISIONS]:
              state[ONA_ACTION_SUBTYPES.DIVISIONS],
            [ONA_ACTION_SUBTYPES.LOCATIONS]:
              state[ONA_ACTION_SUBTYPES.LOCATIONS],
            [ONA_ACTION_SUBTYPES.ROLES]: state[ONA_ACTION_SUBTYPES.ROLES],
            currentFilters: {
              ...state.currentFilters,
              [action.subtype]: action.data.option,
            },
          };
      }
    case ONA_ACTIONS.LOAD_ONA_DATA:
      return {
        ...state,
        [action.subtype]: {
          ...state[action.subtype],
          loading: true,
          isPending: false,
          error: null,
        },
      };

    case ONA_ACTIONS.LOAD_ONA_DATA_SUCCESS:
      const processedData = (() => {
        switch (action.subtype) {
          case ONA_ACTION_SUBTYPES.GRAPH:
            return processEmployeeGraphData(action.data.employees_data);

          default:
            return action.data;
        }
      })();

      return {
        ...state,
        [action.subtype]: {
          ...state[action.subtype],
          loading: false,
          loaded: true,
          data: processedData,
          error: null,
        },
      };

    case ONA_ACTIONS.LOAD_ONA_DATA_IS_PENDING:
      return {
        ...state,
        [action.subtype]: {
          ...state[action.subtype],
          loading: false,
          loaded: true,
          data: null,
          error: null,
          isPending: true,
        },
      };

    case ONA_ACTIONS.LOAD_ONA_DATA_ERROR:
      return {
        ...state,
        [action.subtype]: {
          ...state[action.subtype],
          loading: false,
          loaded: true,
          error: action.message,
        },
      };

    default:
      return state;
  }
};

export default ONA;

export interface WithONA {
  ONA: ONAState;
}

export const selectONA = (store: WithONA) => store.ONA;

export const selectDepartments = createSelector(
  selectONA,
  (ONA) => ONA[ONA_ACTION_SUBTYPES.DEPARTMENTS],
);

export const selectDivisions = createSelector(
  selectONA,
  (ONA) => ONA[ONA_ACTION_SUBTYPES.DIVISIONS],
);

export const selectLocations = createSelector(
  selectONA,
  (ONA) => ONA[ONA_ACTION_SUBTYPES.LOCATIONS],
);

export const selectRoles = createSelector(
  selectONA,
  (ONA) => ONA[ONA_ACTION_SUBTYPES.ROLES],
);

export const selectCurrentFilters = createSelector(
  selectONA,
  (ONA) => ONA.currentFilters,
);

export const selectAutoCompleteData = createSelector(
  selectONA,
  (ONA) => ONA[ONA_ACTION_SUBTYPES.SEARCH_EMPLOYEES],
);
