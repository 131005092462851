import * as yup from 'yup';
import { Company } from '../models/company';

export const localStorageSchemas = {
  accessToken: yup.string().required(),
  company: yup.object<Company>().required(),
};

export type LocalStorageSchemas = typeof localStorageSchemas;
export type LocalStorageKey = keyof LocalStorageSchemas;

export type LocalStorageKeyMap = { [key in LocalStorageKey]: key };
export const localStorageKey: LocalStorageKeyMap = Object.keys(
  localStorageSchemas,
).reduce((map, key) => ({ ...map, [key]: key }), {} as LocalStorageKeyMap);
