// THEME
import { Layout, Menu } from 'antd';
import styled from 'styled-components';
// CONST
import { DIMENSIONS, responsive } from 'Styles/Constants';

const { Sider } = Layout;

export const SidebarWrapper = styled(Sider)`
  margin: 64px 0 0;
  background: #fff;
  overflow: hidden;
  position: relative;
  z-index: 2;
  left: 0;

  .icon-image {
    align-items: center;
    display: flex;
  }
  .ant-layout {
    background: #eaeaea;
  }
  .ant-layout-sider-children {
    border-right: 2px solid #eaeaea;
  }

  .ant-menu-vertical .ant-menu-item::after,
  .ant-menu-vertical-left .ant-menu-item::after,
  .ant-menu-vertical-right .ant-menu-item::after,
  .ant-menu-inline .ant-menu-item::after {
    right: auto;
    left: 0;
    border-width: 5px;
  }

  .menu-rtl li.ant-menu-item::after {
    left: auto;
    right: 0;
  }

  .ant-menu-item-selected {
    border-radius: 0;
  }
  .ant-menu-item {
    border-radius: 0;
    width: 100%;
  }

  ${responsive.PHABLET`
        width : ${(props) => props.collapsed && '0 !important'};
        min-width : ${(props) => props.collapsed && '0 !important'};
        flex : ${(props) => props.collapsed && 'none !important'};
    `}

  ${(props) =>
    props.collapsed
      ? `.icon-image > span {
            display : none;
        }
        .icon-image > .sidebar-icon {
            margin : 0;
        }
        `
      : ''}
`;

export const LogoWrapper = styled.div`
  height: ${DIMENSIONS.HEADER}px;

  .img-logo {
    max-height: 45px;
    max-width: 200px;
    object-fit: contain;
  }
`;

export const MenuItem = styled(Menu.Item)`
  font-size: 16px;
  transition: 0.3s;

  .anticon {
    font-size: 16px;
  }
`;
