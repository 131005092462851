//THEME
import React from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import history from 'services/history';
import Website from './Components/Website';
import { store } from './Redux/Store';
import { ConfigProvider } from 'antd';
import enUS from 'antd/locale/en_US';
import arEG from 'antd/locale/ar_EG';
import { useTheme } from 'hooks/auth';
import { useTranslation } from 'react-i18next';

function App() {
  const { i18n } = useTranslation();
  var direction = 'ltr';
  if (i18n.language === 'ar') {
    direction = 'rtl';
  } else {
  }
  document.body.dir = direction;
  const theme = useTheme();
  return (
    <ConfigProvider
      direction={direction}
      theme={{
        token: {
          colorPrimary: theme,
        },
      }}
      locale={i18n.language === 'ar' ? arEG : enUS}
    >
      <Provider store={store}>
        <Router history={history} basename={process.env.REACT_APP_BASE_PATH}>
          <Website />
        </Router>
      </Provider>
    </ConfigProvider>
  );
}

export default App;
