import { useChatbotTheme } from 'hooks/auth';
import { scrollChat } from './utils';

export const CustomMessage = ({ previousStep, triggerNextStep, steps }) => {
  const value = previousStep.value + ' ';
  const userData = JSON.parse(localStorage.getItem('userData'));
  const userName = userData ? userData.full_name : 'User';
  const chatbotTheme = useChatbotTheme();
  scrollChat();
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        paddingTop: 0,
      }}
    >
      <div
        style={{
          fontSize: 14,
          textAlign: 'end',
          color: chatbotTheme.userFontColor,
          padding: 5,
        }}
      >
        {userName}
      </div>
      <div
        style={{
          maxWidth: '70%',
          minWidth: '5%',
          textAlign: 'left',
          borderRadius: '10px 10px 0px 10px',
          backgroundColor: chatbotTheme.headerBgColor,
          color: chatbotTheme.botFontColor,
          padding: 12,
          fontSize: 14,
        }}
      >
        {value}
      </div>
    </div>
  );
};
