import { useState, useEffect, useCallback } from 'react';
import { withRouter } from 'react-router-dom';

import { Menu, Popover } from 'antd';

// STYLES
import { SidebarWrapper } from './Sidebar.style';

// CONST
import { toggleCollapsed } from 'Redux/Auth/Actions';
import { setCompany } from 'Redux/Company/Actions';
import { connect } from 'react-redux';
import { AppstoreOutlined, SettingFilled } from '@ant-design/icons';
import { ReactSVG } from 'react-svg';
import AssessmentsActiveIcon from '../../../Assets/sidebar-icons/assessments-active.svg';
import AssessmentsInActiveIcon from '../../../Assets/sidebar-icons/assessments-inactive.svg';
import CandidateActiveIcon from '../../../Assets/sidebar-icons/candidate-active.svg';
import CandidateInActiveIcon from '../../../Assets/sidebar-icons/candidate-inactive.svg';
import EmpActiveIcon from '../../../Assets/sidebar-icons/emp-active.svg';
import EmpInActiveIcon from '../../../Assets/sidebar-icons/emp-inactive.svg';
import JobActiveIcon from '../../../Assets/sidebar-icons/job-active.svg';
import JobInActiveIcon from '../../../Assets/sidebar-icons/job-inactive.svg';
import {
  default as ONAActiveIcon,
  default as ONAInActiveIcon,
} from '../../../Assets/sidebar-icons/ona-inactive.svg';
import ReportActiveIcon from '../../../Assets/sidebar-icons/report-active.svg';
import ReportInActiveIcon from '../../../Assets/sidebar-icons/report-inactive.svg';
import {
  URL_ASSESSMENTS,
  URL_CANDIDATES,
  URL_DASHBOARD,
  URL_EMPLOYEES,
  URL_TALENT_INSIGHTS,
  URL_JOBS,
  URL_ONA,
  URL_REPORTS,
  URL_SETTINGS,
} from '../../../Helpers/Paths';
import { useTranslation } from 'react-i18next';

function Sidebar(props) {
  const { t, i18n } = useTranslation();
  let [activeSidebarItems, setActiveSidebarItems] = useState([]);
  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem('userData'));
    const company = JSON.parse(localStorage.getItem('company'));
    const sidebarItems = [
      {
        path: URL_DASHBOARD,
        name: t('sidebar.Dashboard'),
        activeIcon: <AppstoreOutlined className="sidebar-icon whitelabel" />,
        icon: <AppstoreOutlined className="sidebar-icon icon-color" />,
        antdIcon: true,
      },
      {
        path: URL_CANDIDATES,
        name: t('shared.Candidates'),
        activeIcon: (
          <ReactSVG
            beforeInjection={(svg) => {
              svg.classList.add('whitelabel');
              svg.classList.add('sidebar-icon');
            }}
            className="svg-img sidebar-icon"
            src={CandidateActiveIcon}
          />
        ),
        icon: (
          <div
            style={{
              backgroundImage: `url(${CandidateInActiveIcon})`,
            }}
            className="sidebar-icon"
          />
        ),
      },
      {
        path: URL_EMPLOYEES,
        name: t('shared.Employees'),
        activeIcon: (
          <ReactSVG
            beforeInjection={(svg) => {
              svg.classList.add('whitelabel');
              svg.classList.add('sidebar-icon');
            }}
            className="svg-img"
            src={EmpActiveIcon}
          />
        ),
        icon: (
          <div
            style={{
              backgroundImage: `url(${EmpInActiveIcon})`,
            }}
            className="sidebar-icon"
          />
        ),
      },
      {
        path: URL_TALENT_INSIGHTS,
        name: t('shared.Talent Insights'),
        activeIcon: (
          <ReactSVG
            beforeInjection={(svg) => {
              svg.classList.add('whitelabel');
              svg.classList.add('sidebar-icon');
            }}
            className="svg-img"
            src={EmpActiveIcon}
          />
        ),
        icon: (
          <div
            style={{
              backgroundImage: `url(${EmpInActiveIcon})`,
            }}
            className="sidebar-icon"
          />
        ),
      },
      {
        path: URL_JOBS,
        name: t('shared.Jobs'),
        activeIcon: (
          <ReactSVG
            beforeInjection={(svg) => {
              svg.classList.add('whitelabel');
              svg.classList.add('sidebar-icon');
            }}
            className="svg-img"
            src={JobActiveIcon}
          />
        ),
        icon: (
          <div
            style={{
              backgroundImage: `url(${JobInActiveIcon})`,
            }}
            className="sidebar-icon"
          />
        ),
      },
      {
        path: URL_ASSESSMENTS,
        name: t('shared.Assessments'),
        activeIcon: (
          <ReactSVG
            beforeInjection={(svg) => {
              svg.classList.add('whitelabel');
              svg.classList.add('sidebar-icon');
            }}
            className="svg-img"
            src={AssessmentsActiveIcon}
          />
        ),
        icon: (
          <div
            style={{
              backgroundImage: `url(${AssessmentsInActiveIcon})`,
            }}
            className="sidebar-icon"
          />
        ),
      },
      {
        path: URL_REPORTS,
        name: t('shared.Reports'),
        activeIcon: (
          <ReactSVG
            beforeInjection={(svg) => {
              svg.classList.add('whitelabel');
              svg.classList.add('sidebar-icon');
            }}
            className="svg-img"
            src={ReportActiveIcon}
          />
        ),
        icon: (
          <div
            style={{
              backgroundImage: `url(${ReportInActiveIcon})`,
            }}
            className="sidebar-icon icon-color"
          />
        ),
        showDivider: true,
      },
      {
        path: URL_ONA,
        name: t('shared.ONA'),
        activeIcon: (
          <ReactSVG
            beforeInjection={(svg) => {
              svg.classList.add('whitelabel');
            }}
            className="svg-img"
            src={ONAActiveIcon}
          />
        ),
        icon: (
          <div
            style={{
              backgroundImage: `url(${ONAInActiveIcon})`,
            }}
            className="sidebar-icon icon-color"
          />
        ),
        showDivider: true,
      },
      {
        path: URL_SETTINGS,
        name: t('sidebar.Settings'),
        activeIcon: <SettingFilled className="sidebar-icon" />,
        icon: <SettingFilled className="sidebar-icon icon-color" />,
        antdIcon: true,
      },
    ];

    for (const item of sidebarItems) {
      if (item.name === t('shared.ONA')) {
        item.locked = !userData.is_ona_user;
      }
      if (item.name === t('shared.Talent Insights')) {
        item.locked = !company.use_talent_insights;
      }
    }
    setActiveSidebarItems(sidebarItems);
  }, [t]);

  const listItemClick = useCallback(
    (item) => {
      props.history.push(item.path);
    },
    [props.history],
  );

  function getItem(label, key, icon, onClick, disabled, children, type) {
    return {
      key,
      icon,
      onClick,
      disabled,
      children,
      label,
      type,
    };
  }
  useEffect(() => {
    let tempMenuItems = [];
    activeSidebarItems.forEach((item) => {
      tempMenuItems.push(
        getItem(
          item.locked ? (
            <Popover
              content={t('shared.Please contact WhiteBox HR.')}
              title={t('shared.Permission Required')}
            >
              <div>{t(item.name)}</div>
            </Popover>
          ) : (
            <div>{t(item.name)}</div>
          ),
          item.path,
          <div style={{ width: 20, height: 20, minWidth: 20 }}>
            {props.location.pathname === item.path
              ? item.activeIcon
              : item.icon}
          </div>,
          () => {
            item.onClick ? item.onClick() : listItemClick(item);
          },
          item.locked,
        ),
      );
    });
    setMenuItems(tempMenuItems);
  }, [activeSidebarItems, listItemClick, props.location.pathname, t]);

  return (
    <SidebarWrapper
      collapsed={props.auth.collapsed}
      companyid={props.company && props.company.id}
    >
      <Menu
        mode="inline"
        style={{ height: 'inherit' }}
        defaultSelectedKeys={[props.history.location.pathname]}
        selectedKeys={[props.history.location.pathname]}
        className={i18n.dir() === 'rtl' ? 'menu-rtl' : ''}
        items={menuItems}
      ></Menu>
    </SidebarWrapper>
  );
}

export default connect(
  (state) => ({
    auth: state.Auth,
    company: state.Company.company,
  }),
  { toggleCollapsed, setCompany },
)(withRouter(Sidebar));
