import { Button } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, RouteComponentProps } from 'react-router-dom';

const headerHeight = 70;
const footerHeight = 66;

const containerStyle: React.CSSProperties = {
  height: `calc(100vh - ${headerHeight + footerHeight}px)`,
};

const NotFound: React.FC<RouteComponentProps> = (props) => {
  const { t } = useTranslation();
  return (
    <div className="container" style={containerStyle}>
      <div className="h-full flex flex-col justify-center items-center">
        <p>{t('messages.Page not found')}</p>
        <p style={{ margin: '36px 0' }}>
          {t('messages.Unfortunately the page you’re looking for isn’t here.')}
        </p>
        <Link to="/">
          <Button type="primary" shape="round" size="middle">
            {t('shared.Go to Dashboard')}
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
