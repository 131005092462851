import Privacy from 'Components/Commons/Footer/Privacy';
import Terms from 'Components/Commons/Footer/Terms';
import NotFound from 'Components/NotFound/NotFound';
// COMPONENTS
import Login from 'Components/Pages/Login/Login';
import { URL_LOGIN, URL_PRIVACY, URL_TERMS } from 'Helpers/Paths';
import { useIsLoggedIn } from 'hooks/auth';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import RoutesList from './RoutesList.jsx';

function Routes() {
  const isLoggedIn = useIsLoggedIn();

  return (
    <Switch>
      <Route path={URL_LOGIN} component={Login} />
      <Route path={URL_TERMS} component={Terms} />
      <Route path={URL_PRIVACY} component={Privacy} />
      {RoutesList.map((route, index) => (
        <Route
          key={index}
          path={route.path}
          exact={route.exact}
          render={(props) =>
            isLoggedIn ? (
              route.redirect !== undefined ? (
                <Redirect to={route.redirect} />
              ) : (
                <route.component {...props} {...route.props} />
              )
            ) : (
              <Redirect to={URL_LOGIN} />
            )
          }
        />
      ))}
      <Route component={NotFound} />
    </Switch>
  );
}

export default Routes;
