import styled from 'styled-components';
import { responsive, FONTS } from 'Styles/Constants';

export const FooterWrapper = styled.div`
  background: #fafbfc;

  .footer-main {
    float: left;
    width: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
  .footer-copy-right {
    float: left;
    font-weight: 500;
    color: #b1b1b1;
  }
  .footer-link {
    float: right;
  }
  .footer-link > a {
    padding: 10px;
    color: #acacac;
  }

  .footer-copy-right {
    ${responsive.MOBILE`
            float: left;
            width: 100%;
            text-align: center;
            margin-bottom: 10px;
        `}
  }
  .footer-link {
    ${responsive.MOBILE`
            float: left;
            width: 100%;
            text-align: center;
        `}
  }

  .container {
    float: left;
    width: 100%;
    padding: 25px 50px;
  }
  .page-modal-main {
    float: left;
    width: 100%;
    color: #333333;
  }
  .page-modal-main > p {
    line-height: 1.5;
    text-align: justify;
  }
  .page-modal-main > h3 {
    font-size: 21px;
    font-family: ${FONTS.PRIMARY_SEMIBOLD};
  }
  .page-title {
    float: left;
    width: 100%;
    font-size: 26px;
    font-family: ${FONTS.PRIMARY_BOLD};
  }
  .page-modal-main > ul {
    line-height: 1.8;
  }
`;
