import { LocalStorageObserver } from 'models/localStorage';
import { v4 } from 'uuid';

const observers: Array<LocalStorageObserver> = [];

const storage: Storage & {
  subscribe: (observer: Omit<LocalStorageObserver, 'id'>) => () => void;
} = {
  getItem: localStorage.getItem.bind(localStorage),
  get length() {
    return localStorage.length;
  },
  key: localStorage.key.bind(localStorage),
  subscribe: (observer) => {
    const id = v4();

    observers.push({ ...observer, id });

    observer.observe(localStorage.getItem(observer.key));

    return () => {
      const observerIndex = observers.findIndex(
        (observer) => observer.id === id,
      );
      observers.splice(observerIndex, 1);
    };
  },
  setItem: (key, value) => {
    localStorage.setItem(key, value);

    observers
      .filter((observer) => observer.key === key)
      .forEach((observer) => observer.observe(value));
  },
  removeItem: (key) => {
    localStorage.removeItem(key);

    observers
      .filter((observer) => observer.key === key)
      .forEach((observer) => observer.observe(null));
  },
  clear: () => {
    localStorage.clear();

    observers.forEach((observer) => observer.observe(null));
  },
};

export default storage;
