export const ACTION_TYPES = {
  COLLAPSE_CHANGE: 'COLLAPSE_CHANGE',
} as const;

export const toggleCollapsed = () => {
  return {
    type: ACTION_TYPES.COLLAPSE_CHANGE,
  };
};
export type ToggleCollapsed = typeof toggleCollapsed;
export type ToggleCollapsedAction = ReturnType<ToggleCollapsed>;

export type AuthAction = ToggleCollapsedAction;
