import React, { useState } from 'react';
import { Modal } from 'antd';
import { withRouter } from 'react-router-dom';
import { FooterWrapper } from './Footer.style';

import Terms from './Terms';
import { useTranslation } from 'react-i18next';

const Footer = (props) => {
  const [visible, setVisible] = useState(false);
  const [title] = useState('');
  const [modelTitle] = useState('');

  const { t } = useTranslation();

  const handleOk = (e) => {
    setVisible(false);
  };

  const handleCancel = (e) => {
    setVisible(false);
  };

  const currentYear = new Date().getFullYear();
  return (
    <FooterWrapper>
      <div className="footer-main">
        <div className="footer-copy-right">
          ©{t('footer.WhiteboxHR')} {currentYear}
        </div>
        <div className="footer-link">
          <a target="blank" href={process.env.PUBLIC_URL + '/terms'}>
            {t('footer.Terms of Service')}
          </a>
          <a target="blank" href={process.env.PUBLIC_URL + '/privacy'}>
            {t('footer.Privacy Policy')}
          </a>
        </div>
      </div>
      <Modal
        title={modelTitle}
        open={visible}
        footer={null}
        onOk={handleOk}
        onCancel={handleCancel}
        style={{ top: 10 }}
        width="520"
      >
        <Terms page_title={title} />
      </Modal>
    </FooterWrapper>
  );
};

export default withRouter(Footer);
