import { useLocalStorageItem } from './useLocalStorage';
import { COLORS, FONTS } from '../Styles/Constants';

export const useAccessTokenItem = () => {
  return useLocalStorageItem('accessToken');
};

export const useIsLoggedIn = (): boolean => {
  return !!useAccessTokenItem().item;
};

export const useCompany = () => {
  return useLocalStorageItem('company');
};

export const useTheme = () => {
  return useCompany().item?.theme || COLORS.PRIMARY;
};

export const useChatbotTheme = () => {
  const chatbotTheme = {
    color: useTheme(),
    background: '#ffff',
    fontFamily: FONTS.PRIMARY,
    headerBgColor: useCompany().item?.theme.toString() || COLORS.PRIMARY,
    headerFontColor: '#fff',
    headerFontSize: '18px',
    headerFontWeight: 'normal',
    botBubbleColor:
      (useCompany().item?.theme.toString() || COLORS.PRIMARY) + '20',
    botFontColor: '#fff',
    userBubbleColor: '#fff',
    userFontColor: '#4a4a4a',
  };
  return chatbotTheme;
};
